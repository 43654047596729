/**
 * Checks if it's being called in client-side and retrieves
 * the given key's value from Session Storage
 * @param {string} key
 */
const getFromSessionStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(key)
  }
}

/**
 * Checks if it's being called in client-side and creates a
 * new entry for the new pair in Session Storage
 * @param {string} key
 * @param {string} value stringified JSON
 */
const setToSessionStorage = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    try {
      sessionStorage.setItem(key, value)
      return false
    } catch (err) {
      return true
    }
  }
}

/**
 * Checks if it's being called in client-side and retrieves
 * the given key's value from Local Storage
 * @param {string} key
 */
const getFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key)
  }
}

/**
 * Checks if it's being called in client-side and creates a
 * new entry for the new pair in Local Storage
 * @param {string} key
 * @param {string} value stringified JSON
 */
const setToLocalStorage = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    try {
      localStorage.setItem(key, value)
      return false
    } catch (err) {
      return true
    }
  }
}

export { getFromSessionStorage, setToSessionStorage, getFromLocalStorage, setToLocalStorage }
