import { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { getFromSessionStorage } from 'helpers/storageUtils'
import { X_WIZARD_SESSION_TOKEN } from '../constants'

const setWizardSessionToken = (config: InternalAxiosRequestConfig<any>) => {
  config.headers[X_WIZARD_SESSION_TOKEN] = getFromSessionStorage('session_token')
}

const setRequestHeadersInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      setWizardSessionToken(config)
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export { setRequestHeadersInterceptors }
