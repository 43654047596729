/* tslint:disable */
/* eslint-disable */
/**
 * ATV Wizard API
 * ATV Wizard API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Audio
 */
export interface Audio {
    /**
     * 
     * @type {number}
     * @memberof Audio
     */
    'balance': number;
    /**
     * 
     * @type {boolean}
     * @memberof Audio
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface AudioSettings
 */
export interface AudioSettings {
    /**
     * 
     * @type {number}
     * @memberof AudioSettings
     */
    'balance': number;
    /**
     * 
     * @type {boolean}
     * @memberof AudioSettings
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface BaseDevicePresetProDTO
 */
export interface BaseDevicePresetProDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof BaseDevicePresetProDTO
     */
    'default_ocr_files'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof BaseDevicePresetProDTO
     */
    'favorited'?: boolean;
    /**
     * 
     * @type {GraphicsEngineEnum}
     * @memberof BaseDevicePresetProDTO
     */
    'graphics_engine'?: GraphicsEngineEnum;
    /**
     * 
     * @type {ManualScorebugConfiguration}
     * @memberof BaseDevicePresetProDTO
     */
    'manual_scorebug_configuration'?: ManualScorebugConfiguration;
    /**
     * 
     * @type {string}
     * @memberof BaseDevicePresetProDTO
     */
    'name': string;
    /**
     * 
     * @type {Overlays}
     * @memberof BaseDevicePresetProDTO
     */
    'overlays'?: Overlays;
    /**
     * 
     * @type {SportResponseDTO}
     * @memberof BaseDevicePresetProDTO
     */
    'sport': SportResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof BaseDevicePresetProDTO
     */
    'uuid': string;
}


/**
 * 
 * @export
 * @interface CalibrationBaseDTO
 */
export interface CalibrationBaseDTO {
    /**
     * 
     * @type {number}
     * @memberof CalibrationBaseDTO
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof CalibrationBaseDTO
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CalibrationDTO
 */
export interface CalibrationDTO {
    /**
     * 
     * @type {{ [key: string]: CameraOptionsCalibrationDTO; }}
     * @memberof CalibrationDTO
     */
    'cameras_calibrations': { [key: string]: CameraOptionsCalibrationDTO; };
    /**
     * 
     * @type {number}
     * @memberof CalibrationDTO
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof CalibrationDTO
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CalibrationLineDTO
 */
export interface CalibrationLineDTO {
    /**
     * 
     * @type {DirectionEnum}
     * @memberof CalibrationLineDTO
     */
    'direction'?: DirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof CalibrationLineDTO
     */
    'id': number;
    /**
     * 
     * @type {Array<OptIdPointDTO>}
     * @memberof CalibrationLineDTO
     */
    'points': Array<OptIdPointDTO>;
}


/**
 * 
 * @export
 * @interface CalibrationOptionsDTO
 */
export interface CalibrationOptionsDTO {
    /**
     * 
     * @type {{ [key: string]: CameraOptionsCalibrationDTO; }}
     * @memberof CalibrationOptionsDTO
     */
    'cameras_calibrations': { [key: string]: CameraOptionsCalibrationDTO; };
    /**
     * 
     * @type {Image}
     * @memberof CalibrationOptionsDTO
     */
    'field': Image;
    /**
     * 
     * @type {number}
     * @memberof CalibrationOptionsDTO
     */
    'length': number;
    /**
     * 
     * @type {number}
     * @memberof CalibrationOptionsDTO
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface Camera
 */
export interface Camera {
}
/**
 * 
 * @export
 * @interface CameraCalibrationDTO
 */
export interface CameraCalibrationDTO {
    /**
     * 
     * @type {number}
     * @memberof CameraCalibrationDTO
     */
    'camera_id': number;
    /**
     * 
     * @type {string}
     * @memberof CameraCalibrationDTO
     */
    'camera_name': string;
    /**
     * 
     * @type {Field}
     * @memberof CameraCalibrationDTO
     */
    'field'?: Field;
    /**
     * 
     * @type {Image}
     * @memberof CameraCalibrationDTO
     */
    'image': Image;
    /**
     * 
     * @type {Array<CalibrationLineDTO>}
     * @memberof CameraCalibrationDTO
     */
    'lines'?: Array<CalibrationLineDTO>;
    /**
     * 
     * @type {Array<IdPointDTO>}
     * @memberof CameraCalibrationDTO
     */
    'points'?: Array<IdPointDTO>;
}
/**
 * 
 * @export
 * @interface CameraDetailsDTO
 */
export interface CameraDetailsDTO {
    /**
     * 
     * @type {EnclosureTypeEnum}
     * @memberof CameraDetailsDTO
     */
    'enclosure_type'?: EnclosureTypeEnum;
    /**
     * 
     * @type {FieldTypeEnum}
     * @memberof CameraDetailsDTO
     */
    'field_type'?: FieldTypeEnum;
    /**
     * 
     * @type {Array<FieldDTO>}
     * @memberof CameraDetailsDTO
     */
    'fields'?: Array<FieldDTO>;
    /**
     * 
     * @type {FrameRateEnum}
     * @memberof CameraDetailsDTO
     */
    'framerate'?: FrameRateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CameraDetailsDTO
     */
    'is_configured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CameraDetailsDTO
     */
    'is_installed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CameraDetailsDTO
     */
    'is_licensed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CameraDetailsDTO
     */
    'is_recording'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CameraDetailsDTO
     */
    'is_registered'?: boolean;
    /**
     * 
     * @type {LensSizeEnum}
     * @memberof CameraDetailsDTO
     */
    'lens_size'?: LensSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof CameraDetailsDTO
     */
    'name': string;
    /**
     * 
     * @type {OrganizationDTO}
     * @memberof CameraDetailsDTO
     */
    'organization': OrganizationDTO;
    /**
     * 
     * @type {ProducerDTO}
     * @memberof CameraDetailsDTO
     */
    'producer': ProducerDTO;
    /**
     * 
     * @type {Array<SoftwareInstalledDTO>}
     * @memberof CameraDetailsDTO
     */
    'software_installed'?: Array<SoftwareInstalledDTO>;
    /**
     * 
     * @type {Array<DeploymentDTO>}
     * @memberof CameraDetailsDTO
     */
    'software_installing'?: Array<DeploymentDTO>;
    /**
     * 
     * @type {DeviceStatusEnum}
     * @memberof CameraDetailsDTO
     */
    'status'?: DeviceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CameraDetailsDTO
     */
    'uuid'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CameraNameEnum = {
    Camera: 'camera',
    CameraLeft: 'camera_left',
    CameraCenterleft: 'camera_centerleft',
    CameraCenterright: 'camera_centerright',
    CameraRight: 'camera_right'
} as const;

export type CameraNameEnum = typeof CameraNameEnum[keyof typeof CameraNameEnum];


/**
 * 
 * @export
 * @interface CameraOptionsCalibrationDTO
 */
export interface CameraOptionsCalibrationDTO {
    /**
     * 
     * @type {number}
     * @memberof CameraOptionsCalibrationDTO
     */
    'camera_id': number;
    /**
     * 
     * @type {string}
     * @memberof CameraOptionsCalibrationDTO
     */
    'camera_name': string;
    /**
     * 
     * @type {Array<CalibrationLineDTO>}
     * @memberof CameraOptionsCalibrationDTO
     */
    'lines'?: Array<CalibrationLineDTO>;
    /**
     * 
     * @type {Array<IdPointDTO>}
     * @memberof CameraOptionsCalibrationDTO
     */
    'points'?: Array<IdPointDTO>;
}
/**
 * 
 * @export
 * @interface ChangeSetting
 */
export interface ChangeSetting {
    /**
     * 
     * @type {string}
     * @memberof ChangeSetting
     */
    'name': string;
    /**
     * 
     * @type {Value}
     * @memberof ChangeSetting
     */
    'value': Value;
}
/**
 * 
 * @export
 * @interface CommonValueDisplayNameDTO
 */
export interface CommonValueDisplayNameDTO {
    /**
     * 
     * @type {string}
     * @memberof CommonValueDisplayNameDTO
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof CommonValueDisplayNameDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface DefaultResponseDTO
 */
export interface DefaultResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof DefaultResponseDTO
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultResponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface DefaultResponseDTO1
 */
export interface DefaultResponseDTO1 {
    /**
     * 
     * @type {number}
     * @memberof DefaultResponseDTO1
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultResponseDTO1
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface DefaultResponseDTO2
 */
export interface DefaultResponseDTO2 {
    /**
     * 
     * @type {number}
     * @memberof DefaultResponseDTO2
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultResponseDTO2
     */
    'message': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeployActionEnum = {
    Install: 'install',
    Uninstall: 'uninstall',
    Upgrade: 'upgrade'
} as const;

export type DeployActionEnum = typeof DeployActionEnum[keyof typeof DeployActionEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const DeployStatusEnum = {
    Creating: 'creating',
    Initializing: 'initializing',
    InProgress: 'in_progress',
    AlreadyRunning: 'already_running',
    Succeed: 'succeed',
    Failed: 'failed'
} as const;

export type DeployStatusEnum = typeof DeployStatusEnum[keyof typeof DeployStatusEnum];


/**
 * 
 * @export
 * @interface DeploymentDTO
 */
export interface DeploymentDTO {
    /**
     * 
     * @type {DeployActionEnum}
     * @memberof DeploymentDTO
     */
    'action': DeployActionEnum;
    /**
     * 
     * @type {DeployStatusEnum}
     * @memberof DeploymentDTO
     */
    'status': DeployStatusEnum;
    /**
     * 
     * @type {VersionDTO}
     * @memberof DeploymentDTO
     */
    'version': VersionDTO;
}


/**
 * 
 * @export
 * @interface DestinationsInfo
 */
export interface DestinationsInfo {
    /**
     * 
     * @type {Array<PresetDestinationDTO>}
     * @memberof DestinationsInfo
     */
    'destinations'?: Array<PresetDestinationDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof DestinationsInfo
     */
    'panorama_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DestinationsInfo
     */
    'quality': string;
}
/**
 * 
 * @export
 * @interface DestinationsInfoDTO
 */
export interface DestinationsInfoDTO {
    /**
     * 
     * @type {Array<PresetDestinationDTO>}
     * @memberof DestinationsInfoDTO
     */
    'destinations'?: Array<PresetDestinationDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof DestinationsInfoDTO
     */
    'panorama_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DestinationsInfoDTO
     */
    'quality': string;
}
/**
 * 
 * @export
 * @interface Details
 */
export interface Details {
    /**
     * 
     * @type {EnclosureTypeEnum}
     * @memberof Details
     */
    'enclosure_type'?: EnclosureTypeEnum;
    /**
     * 
     * @type {FieldTypeEnum}
     * @memberof Details
     */
    'field_type'?: FieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Details
     */
    'name': string;
    /**
     * 
     * @type {TimeZonesEnum}
     * @memberof Details
     */
    'timezone': TimeZonesEnum;
}


/**
 * 
 * @export
 * @interface Details1
 */
export interface Details1 {
    /**
     * 
     * @type {string}
     * @memberof Details1
     */
    'name': string;
    /**
     * 
     * @type {TimeZonesEnum}
     * @memberof Details1
     */
    'timezone': TimeZonesEnum;
}


/**
 * Main DTO to get all the information about a device
 * @export
 * @interface DeviceConfigurationDTO
 */
export interface DeviceConfigurationDTO {
    /**
     * 
     * @type {Array<SportCalibration>}
     * @memberof DeviceConfigurationDTO
     */
    'configured_sports'?: Array<SportCalibration>;
    /**
     * 
     * @type {Details}
     * @memberof DeviceConfigurationDTO
     */
    'details'?: Details;
    /**
     * 
     * @type {string}
     * @memberof DeviceConfigurationDTO
     */
    'device_uuid': string;
    /**
     * 
     * @type {ScoreboardConfiguration}
     * @memberof DeviceConfigurationDTO
     */
    'scoreboard_configuration'?: ScoreboardConfiguration;
}
/**
 * 
 * @export
 * @interface DeviceDTO
 */
export interface DeviceDTO {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDTO
     */
    'is_configured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDTO
     */
    'is_installed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDTO
     */
    'is_licensed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDTO
     */
    'is_recording'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDTO
     */
    'is_registered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    'name': string;
    /**
     * 
     * @type {OrganizationDTO}
     * @memberof DeviceDTO
     */
    'organization': OrganizationDTO;
    /**
     * 
     * @type {ProducerDTO}
     * @memberof DeviceDTO
     */
    'producer': ProducerDTO;
    /**
     * 
     * @type {Array<SoftwareInstalledDTO>}
     * @memberof DeviceDTO
     */
    'software_installed'?: Array<SoftwareInstalledDTO>;
    /**
     * 
     * @type {Array<DeploymentDTO>}
     * @memberof DeviceDTO
     */
    'software_installing'?: Array<DeploymentDTO>;
    /**
     * 
     * @type {DeviceStatusEnum}
     * @memberof DeviceDTO
     */
    'status'?: DeviceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    'uuid'?: string;
}


/**
 * 
 * @export
 * @interface DeviceDeploymentDTO
 */
export interface DeviceDeploymentDTO {
    /**
     * 
     * @type {DeployActionEnum}
     * @memberof DeviceDeploymentDTO
     */
    'action': DeployActionEnum;
    /**
     * 
     * @type {DeployStatusEnum}
     * @memberof DeviceDeploymentDTO
     */
    'status': DeployStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeploymentDTO
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeploymentDTO
     */
    'version_number'?: string;
}


/**
 * DTO based on the first step of a field configuration
 * @export
 * @interface DeviceDetailsDTO
 */
export interface DeviceDetailsDTO {
    /**
     * 
     * @type {EnclosureTypeEnum}
     * @memberof DeviceDetailsDTO
     */
    'enclosure_type'?: EnclosureTypeEnum;
    /**
     * 
     * @type {FieldTypeEnum}
     * @memberof DeviceDetailsDTO
     */
    'field_type'?: FieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsDTO
     */
    'name': string;
    /**
     * 
     * @type {TimeZonesEnum}
     * @memberof DeviceDetailsDTO
     */
    'timezone': TimeZonesEnum;
}


/**
 * 
 * @export
 * @interface DeviceDetailsProDTO
 */
export interface DeviceDetailsProDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceDetailsProDTO
     */
    'name': string;
    /**
     * 
     * @type {TimeZonesEnum}
     * @memberof DeviceDetailsProDTO
     */
    'timezone': TimeZonesEnum;
}


/**
 * 
 * @export
 * @interface DeviceOCRFilesOptions
 */
export interface DeviceOCRFilesOptions {
    /**
     * 
     * @type {string}
     * @memberof DeviceOCRFilesOptions
     */
    'display_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceOCRFilesOptions
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeviceOCRFilesOptions
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface DevicePresetDTO
 */
export interface DevicePresetDTO {
    /**
     * 
     * @type {AudioSettings}
     * @memberof DevicePresetDTO
     */
    'audio': AudioSettings;
    /**
     * 
     * @type {{ [key: string]: Camera; }}
     * @memberof DevicePresetDTO
     */
    'camera': { [key: string]: Camera; };
    /**
     * 
     * @type {Array<number>}
     * @memberof DevicePresetDTO
     */
    'default_ocr_files'?: Array<number>;
    /**
     * 
     * @type {DestinationsInfoDTO}
     * @memberof DevicePresetDTO
     */
    'destinations_info': DestinationsInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof DevicePresetDTO
     */
    'device_uuid': string;
    /**
     * 
     * @type {boolean}
     * @memberof DevicePresetDTO
     */
    'favorited'?: boolean;
    /**
     * 
     * @type {GraphicsEngineEnum}
     * @memberof DevicePresetDTO
     */
    'graphics_engine'?: GraphicsEngineEnum;
    /**
     * 
     * @type {ManualScorebugConfiguration}
     * @memberof DevicePresetDTO
     */
    'manual_scorebug_configuration'?: ManualScorebugConfiguration;
    /**
     * 
     * @type {CommonValueDisplayNameDTO}
     * @memberof DevicePresetDTO
     */
    'mode': CommonValueDisplayNameDTO;
    /**
     * 
     * @type {string}
     * @memberof DevicePresetDTO
     */
    'name': string;
    /**
     * 
     * @type {OverlayDTO}
     * @memberof DevicePresetDTO
     */
    'overlays': OverlayDTO;
    /**
     * 
     * @type {SportResponseDTO}
     * @memberof DevicePresetDTO
     */
    'sport': SportResponseDTO;
    /**
     * 
     * @type {Style}
     * @memberof DevicePresetDTO
     */
    'style'?: Style;
    /**
     * 
     * @type {string}
     * @memberof DevicePresetDTO
     */
    'uuid'?: string;
}


/**
 * Main DTO to get all the information about a device
 * @export
 * @interface DeviceProConfigurationDTO
 */
export interface DeviceProConfigurationDTO {
    /**
     * 
     * @type {Details1}
     * @memberof DeviceProConfigurationDTO
     */
    'details'?: Details1;
    /**
     * 
     * @type {string}
     * @memberof DeviceProConfigurationDTO
     */
    'device_uuid': string;
    /**
     * 
     * @type {ScoreboardConfiguration}
     * @memberof DeviceProConfigurationDTO
     */
    'scoreboard_configuration'?: ScoreboardConfiguration;
}
/**
 * 
 * @export
 * @interface DeviceRegisterDTO
 */
export interface DeviceRegisterDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceRegisterDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceRegisterDTO
     */
    'serial_number_producer': string;
}
/**
 * 
 * @export
 * @interface DeviceRegisterResponseDTO
 */
export interface DeviceRegisterResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceRegisterResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {TimeZonesEnum}
     * @memberof DeviceRegisterResponseDTO
     */
    'timezone': TimeZonesEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceRegisterResponseDTO
     */
    'uuid'?: string;
}


/**
 * DTO based on the third step of a field configuration
 * @export
 * @interface DeviceScoreboardDTO
 */
export interface DeviceScoreboardDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceScoreboardDTO
     */
    'input_plugin'?: string;
    /**
     * 
     * @type {Array<OcrFilesDTO>}
     * @memberof DeviceScoreboardDTO
     */
    'ocr_files'?: Array<OcrFilesDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceScoreboardDTO
     */
    'ocr_files_to_delete'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeviceStatusEnum = {
    Unknown: 'unknown',
    Offline: 'offline',
    Unregistered: 'unregistered',
    Unlicensed: 'unlicensed',
    NotInstalled: 'not_installed',
    NotConfigured: 'not_configured',
    Recording: 'recording',
    Ready: 'ready'
} as const;

export type DeviceStatusEnum = typeof DeviceStatusEnum[keyof typeof DeviceStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const DevicesSortOptions = {
    Name: 'name'
} as const;

export type DevicesSortOptions = typeof DevicesSortOptions[keyof typeof DevicesSortOptions];


/**
 * 
 * @export
 * @enum {string}
 */

export const DirectionEnum = {
    Horizontal: 'horizontal',
    Vertical: 'vertical'
} as const;

export type DirectionEnum = typeof DirectionEnum[keyof typeof DirectionEnum];


/**
 * 
 * @export
 * @interface DuplicatePresetDTO
 */
export interface DuplicatePresetDTO {
    /**
     * 
     * @type {string}
     * @memberof DuplicatePresetDTO
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface EditCameraDetailsDTO
 */
export interface EditCameraDetailsDTO {
    /**
     * 
     * @type {EnclosureTypeEnum}
     * @memberof EditCameraDetailsDTO
     */
    'enclosure_type'?: EnclosureTypeEnum;
    /**
     * 
     * @type {FieldTypeEnum}
     * @memberof EditCameraDetailsDTO
     */
    'field_type'?: FieldTypeEnum;
    /**
     * 
     * @type {FrameRateEnum}
     * @memberof EditCameraDetailsDTO
     */
    'framerate': FrameRateEnum;
    /**
     * 
     * @type {LensSizeEnum}
     * @memberof EditCameraDetailsDTO
     */
    'lens_size'?: LensSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof EditCameraDetailsDTO
     */
    'name': string;
}


/**
 * Main DTO to edit and configure all the information about a device
 * @export
 * @interface EditDeviceConfigurationDTO
 */
export interface EditDeviceConfigurationDTO {
    /**
     * 
     * @type {Array<SportCalibration>}
     * @memberof EditDeviceConfigurationDTO
     */
    'configured_sports'?: Array<SportCalibration>;
    /**
     * 
     * @type {Details}
     * @memberof EditDeviceConfigurationDTO
     */
    'details'?: Details;
    /**
     * 
     * @type {ScoreboardConfiguration1}
     * @memberof EditDeviceConfigurationDTO
     */
    'scoreboard_configuration'?: ScoreboardConfiguration1;
}
/**
 * 
 * @export
 * @interface EditDeviceDetailsDTO
 */
export interface EditDeviceDetailsDTO {
    /**
     * 
     * @type {FieldTypeEnum}
     * @memberof EditDeviceDetailsDTO
     */
    'field_type'?: FieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EditDeviceDetailsDTO
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface EditDevicePresetDTO
 */
export interface EditDevicePresetDTO {
    /**
     * 
     * @type {AudioSettings}
     * @memberof EditDevicePresetDTO
     */
    'audio': AudioSettings;
    /**
     * 
     * @type {{ [key: string]: Camera; }}
     * @memberof EditDevicePresetDTO
     */
    'camera': { [key: string]: Camera; };
    /**
     * 
     * @type {Array<number>}
     * @memberof EditDevicePresetDTO
     */
    'default_ocr_files'?: Array<number>;
    /**
     * 
     * @type {DestinationsInfoDTO}
     * @memberof EditDevicePresetDTO
     */
    'destinations_info': DestinationsInfoDTO;
    /**
     * 
     * @type {boolean}
     * @memberof EditDevicePresetDTO
     */
    'favorited'?: boolean;
    /**
     * 
     * @type {GraphicsEngineEnum}
     * @memberof EditDevicePresetDTO
     */
    'graphics_engine'?: GraphicsEngineEnum;
    /**
     * 
     * @type {ManualScorebugConfiguration}
     * @memberof EditDevicePresetDTO
     */
    'manual_scorebug_configuration'?: ManualScorebugConfiguration;
    /**
     * 
     * @type {CommonValueDisplayNameDTO}
     * @memberof EditDevicePresetDTO
     */
    'mode': CommonValueDisplayNameDTO;
    /**
     * 
     * @type {string}
     * @memberof EditDevicePresetDTO
     */
    'name': string;
    /**
     * 
     * @type {OverlayDTO}
     * @memberof EditDevicePresetDTO
     */
    'overlays': OverlayDTO;
    /**
     * 
     * @type {SportResponseDTO}
     * @memberof EditDevicePresetDTO
     */
    'sport': SportResponseDTO;
    /**
     * 
     * @type {Style}
     * @memberof EditDevicePresetDTO
     */
    'style'?: Style;
    /**
     * 
     * @type {string}
     * @memberof EditDevicePresetDTO
     */
    'uuid'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EnclosureTypeEnum = {
    Indoor: 'Indoor',
    Outdoor: 'Outdoor'
} as const;

export type EnclosureTypeEnum = typeof EnclosureTypeEnum[keyof typeof EnclosureTypeEnum];


/**
 * 
 * @export
 * @interface ExceptionDetail
 */
export interface ExceptionDetail {
    /**
     * 
     * @type {number}
     * @memberof ExceptionDetail
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionDetail
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface FavoritePreset
 */
export interface FavoritePreset {
    /**
     * 
     * @type {boolean}
     * @memberof FavoritePreset
     */
    'favorited': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FeedNameEnum = {
    CleanFeed: 'clean_feed',
    Panorama: 'panorama'
} as const;

export type FeedNameEnum = typeof FeedNameEnum[keyof typeof FeedNameEnum];


/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {File}
     * @memberof Field
     */
    'base64': File;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    'format': string;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface FieldDTO
 */
export interface FieldDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FieldDTO
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldDTO
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FieldTypeEnum = {
    Indoor: 'Indoor',
    Outdoor: 'Outdoor'
} as const;

export type FieldTypeEnum = typeof FieldTypeEnum[keyof typeof FieldTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const FrameRateEnum = {
    NUMBER_25: 25,
    NUMBER_30: 30
} as const;

export type FrameRateEnum = typeof FrameRateEnum[keyof typeof FrameRateEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const GraphicsEngineEnum = {
    SmartScorebug: 'smart_scorebug',
    CustomHtml: 'custom_html',
    ManualScorebug: 'manual_scorebug'
} as const;

export type GraphicsEngineEnum = typeof GraphicsEngineEnum[keyof typeof GraphicsEngineEnum];


/**
 * 
 * @export
 * @interface HTMLOverlayDTO
 */
export interface HTMLOverlayDTO {
    /**
     * 
     * @type {number}
     * @memberof HTMLOverlayDTO
     */
    'delay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HTMLOverlayDTO
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HTMLOverlayDTO
     */
    'is_manual'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HTMLOverlayDTO
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HtmlOverlay
 */
export interface HtmlOverlay {
    /**
     * 
     * @type {number}
     * @memberof HtmlOverlay
     */
    'delay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlOverlay
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlOverlay
     */
    'is_manual'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlOverlay
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface IdPointDTO
 */
export interface IdPointDTO {
    /**
     * 
     * @type {number}
     * @memberof IdPointDTO
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof IdPointDTO
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof IdPointDTO
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {File}
     * @memberof Image
     */
    'base64': File;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'format': string;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface Image1
 */
export interface Image1 {
    /**
     * 
     * @type {File}
     * @memberof Image1
     */
    'base64': File;
    /**
     * 
     * @type {string}
     * @memberof Image1
     */
    'format': string;
    /**
     * 
     * @type {number}
     * @memberof Image1
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Image1
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface InitPresetDTO
 */
export interface InitPresetDTO {
    /**
     * 
     * @type {Audio}
     * @memberof InitPresetDTO
     */
    'audio'?: Audio;
    /**
     * 
     * @type {{ [key: string]: Camera; }}
     * @memberof InitPresetDTO
     */
    'camera'?: { [key: string]: Camera; };
    /**
     * 
     * @type {Array<number>}
     * @memberof InitPresetDTO
     */
    'default_ocr_files'?: Array<number>;
    /**
     * 
     * @type {DestinationsInfo}
     * @memberof InitPresetDTO
     */
    'destinations_info'?: DestinationsInfo;
    /**
     * 
     * @type {boolean}
     * @memberof InitPresetDTO
     */
    'favorited'?: boolean;
    /**
     * 
     * @type {ManualScorebugConfiguration}
     * @memberof InitPresetDTO
     */
    'manual_scorebug_configuration'?: ManualScorebugConfiguration;
    /**
     * 
     * @type {string}
     * @memberof InitPresetDTO
     */
    'mode': string;
    /**
     * 
     * @type {string}
     * @memberof InitPresetDTO
     */
    'name': string;
    /**
     * 
     * @type {Overlays1}
     * @memberof InitPresetDTO
     */
    'overlays'?: Overlays1;
    /**
     * 
     * @type {string}
     * @memberof InitPresetDTO
     */
    'sport_uuid': string;
    /**
     * 
     * @type {string}
     * @memberof InitPresetDTO
     */
    'style'?: string;
    /**
     * 
     * @type {string}
     * @memberof InitPresetDTO
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LensSizeEnum = {
    Xs: 'XS',
    S: 'S',
    M: 'M',
    L: 'L'
} as const;

export type LensSizeEnum = typeof LensSizeEnum[keyof typeof LensSizeEnum];


/**
 * 
 * @export
 * @interface LicenseDeviceDTO
 */
export interface LicenseDeviceDTO {
    /**
     * 
     * @type {string}
     * @memberof LicenseDeviceDTO
     */
    'ticket_id': string;
}
/**
 * 
 * @export
 * @interface LocationInner
 */
export interface LocationInner {
}
/**
 * 
 * @export
 * @interface ManualScorebugConfiguration
 */
export interface ManualScorebugConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof ManualScorebugConfiguration
     */
    'countdown'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualScorebugConfiguration
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManualScorebugConfiguration
     */
    'num_periods'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManualScorebugConfiguration
     */
    'overtime_duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualScorebugConfiguration
     */
    'period_duration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManualScorebugConfiguration
     */
    'reset_timer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManualScorebugConfiguration
     */
    'stop_timer'?: boolean;
}
/**
 * 
 * @export
 * @interface ManualScorebugConfigurationDTO
 */
export interface ManualScorebugConfigurationDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ManualScorebugConfigurationDTO
     */
    'countdown'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualScorebugConfigurationDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManualScorebugConfigurationDTO
     */
    'num_periods'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManualScorebugConfigurationDTO
     */
    'overtime_duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualScorebugConfigurationDTO
     */
    'period_duration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManualScorebugConfigurationDTO
     */
    'reset_timer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManualScorebugConfigurationDTO
     */
    'stop_timer'?: boolean;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
}
/**
 * 
 * @export
 * @interface OcrFilesDTO
 */
export interface OcrFilesDTO {
    /**
     * 
     * @type {string}
     * @memberof OcrFilesDTO
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcrFilesDTO
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcrFilesDTO
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof OcrFilesDTO
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface OptIdPointDTO
 */
export interface OptIdPointDTO {
    /**
     * 
     * @type {number}
     * @memberof OptIdPointDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OptIdPointDTO
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof OptIdPointDTO
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'validation_regex'?: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface OptionDetails
 */
export interface OptionDetails {
    /**
     * 
     * @type {string}
     * @memberof OptionDetails
     */
    'name': string;
    /**
     * 
     * @type {Array<Option>}
     * @memberof OptionDetails
     */
    'opts': Array<Option>;
    /**
     * 
     * @type {OptionTypeEnum}
     * @memberof OptionDetails
     */
    'type': OptionTypeEnum;
    /**
     * 
     * @type {UiHintEnum}
     * @memberof OptionDetails
     */
    'ui_hint'?: UiHintEnum;
    /**
     * 
     * @type {string}
     * @memberof OptionDetails
     */
    'units'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const OptionTypeEnum = {
    Discrete: 'Discrete',
    Continuous: 'Continuous',
    Boolean: 'Boolean',
    Rectangle: 'Rectangle'
} as const;

export type OptionTypeEnum = typeof OptionTypeEnum[keyof typeof OptionTypeEnum];


/**
 * 
 * @export
 * @interface OrganizationDTO
 */
export interface OrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OverlayDTO
 */
export interface OverlayDTO {
    /**
     * 
     * @type {HtmlOverlay}
     * @memberof OverlayDTO
     */
    'html_overlay'?: HtmlOverlay;
    /**
     * 
     * @type {Array<PresetImageDTO>}
     * @memberof OverlayDTO
     */
    'image_overlays'?: Array<PresetImageDTO>;
    /**
     * 
     * @type {PipScoreboard}
     * @memberof OverlayDTO
     */
    'pip_scoreboard'?: PipScoreboard;
}
/**
 * 
 * @export
 * @interface Overlays
 */
export interface Overlays {
    /**
     * 
     * @type {HtmlOverlay}
     * @memberof Overlays
     */
    'html_overlay'?: HtmlOverlay;
}
/**
 * 
 * @export
 * @interface Overlays1
 */
export interface Overlays1 {
    /**
     * 
     * @type {HtmlOverlay}
     * @memberof Overlays1
     */
    'html_overlay'?: HtmlOverlay;
    /**
     * 
     * @type {Array<PresetImageDTO>}
     * @memberof Overlays1
     */
    'image_overlays'?: Array<PresetImageDTO>;
    /**
     * 
     * @type {PipScoreboard}
     * @memberof Overlays1
     */
    'pip_scoreboard'?: PipScoreboard;
}
/**
 * 
 * @export
 * @interface PageDeviceDTO
 */
export interface PageDeviceDTO {
    /**
     * 
     * @type {Array<DeviceDTO>}
     * @memberof PageDeviceDTO
     */
    'items': Array<DeviceDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageDeviceDTO
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageDeviceDTO
     */
    'pages': number;
    /**
     * 
     * @type {number}
     * @memberof PageDeviceDTO
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof PageDeviceDTO
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PanoramaCalibrationDTO
 */
export interface PanoramaCalibrationDTO {
    /**
     * 
     * @type {Image}
     * @memberof PanoramaCalibrationDTO
     */
    'field': Image;
    /**
     * 
     * @type {Image}
     * @memberof PanoramaCalibrationDTO
     */
    'image': Image;
}
/**
 * 
 * @export
 * @interface Payload
 */
export interface Payload {
    /**
     * 
     * @type {Array<SportCalibration>}
     * @memberof Payload
     */
    'configured_sports'?: Array<SportCalibration>;
    /**
     * 
     * @type {Details}
     * @memberof Payload
     */
    'details'?: Details;
    /**
     * 
     * @type {ScoreboardConfiguration1}
     * @memberof Payload
     */
    'scoreboard_configuration'?: ScoreboardConfiguration1;
}
/**
 * 
 * @export
 * @interface Payload1
 */
export interface Payload1 {
    /**
     * 
     * @type {Audio}
     * @memberof Payload1
     */
    'audio'?: Audio;
    /**
     * 
     * @type {{ [key: string]: Camera; }}
     * @memberof Payload1
     */
    'camera'?: { [key: string]: Camera; };
    /**
     * 
     * @type {Array<number>}
     * @memberof Payload1
     */
    'default_ocr_files'?: Array<number>;
    /**
     * 
     * @type {DestinationsInfo}
     * @memberof Payload1
     */
    'destinations_info'?: DestinationsInfo;
    /**
     * 
     * @type {boolean}
     * @memberof Payload1
     */
    'favorited'?: boolean;
    /**
     * 
     * @type {ManualScorebugConfiguration}
     * @memberof Payload1
     */
    'manual_scorebug_configuration'?: ManualScorebugConfiguration;
    /**
     * 
     * @type {string}
     * @memberof Payload1
     */
    'mode': string;
    /**
     * 
     * @type {string}
     * @memberof Payload1
     */
    'name': string;
    /**
     * 
     * @type {Overlays1}
     * @memberof Payload1
     */
    'overlays'?: Overlays1;
    /**
     * 
     * @type {string}
     * @memberof Payload1
     */
    'sport_uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Payload1
     */
    'style'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payload1
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface Payload2
 */
export interface Payload2 {
    /**
     * 
     * @type {string}
     * @memberof Payload2
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PipScoreboard
 */
export interface PipScoreboard {
    /**
     * 
     * @type {PositionDTO}
     * @memberof PipScoreboard
     */
    'position': PositionDTO;
    /**
     * 
     * @type {Array<PointDTO>}
     * @memberof PipScoreboard
     */
    'source_crop': Array<PointDTO>;
}
/**
 * 
 * @export
 * @interface PipScoreboardDTO
 */
export interface PipScoreboardDTO {
    /**
     * 
     * @type {PositionDTO}
     * @memberof PipScoreboardDTO
     */
    'position': PositionDTO;
    /**
     * 
     * @type {Array<PointDTO>}
     * @memberof PipScoreboardDTO
     */
    'source_crop': Array<PointDTO>;
}
/**
 * 
 * @export
 * @interface PointDTO
 */
export interface PointDTO {
    /**
     * 
     * @type {number}
     * @memberof PointDTO
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof PointDTO
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface PositionDTO
 */
export interface PositionDTO {
    /**
     * 
     * @type {number}
     * @memberof PositionDTO
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof PositionDTO
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof PositionDTO
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof PositionDTO
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface PresetDestinationDTO
 */
export interface PresetDestinationDTO {
    /**
     * 
     * @type {string}
     * @memberof PresetDestinationDTO
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof PresetDestinationDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PresetDestinationDTO
     */
    'protocol': string;
    /**
     * 
     * @type {string}
     * @memberof PresetDestinationDTO
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface PresetImageDTO
 */
export interface PresetImageDTO {
    /**
     * 
     * @type {Image1}
     * @memberof PresetImageDTO
     */
    'image'?: Image1;
    /**
     * 
     * @type {string}
     * @memberof PresetImageDTO
     */
    'image_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PresetImageDTO
     */
    'name': string;
    /**
     * 
     * @type {PositionDTO}
     * @memberof PresetImageDTO
     */
    'position': PositionDTO;
    /**
     * 
     * @type {string}
     * @memberof PresetImageDTO
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface PresetProOverlaysDTO
 */
export interface PresetProOverlaysDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof PresetProOverlaysDTO
     */
    'default_ocr_files'?: Array<number>;
    /**
     * 
     * @type {GraphicsEngineEnum}
     * @memberof PresetProOverlaysDTO
     */
    'graphics_engine'?: GraphicsEngineEnum;
    /**
     * 
     * @type {ManualScorebugConfiguration}
     * @memberof PresetProOverlaysDTO
     */
    'manual_scorebug_configuration'?: ManualScorebugConfiguration;
    /**
     * 
     * @type {string}
     * @memberof PresetProOverlaysDTO
     */
    'name': string;
    /**
     * 
     * @type {Overlays}
     * @memberof PresetProOverlaysDTO
     */
    'overlays'?: Overlays;
    /**
     * 
     * @type {SportResponseDTO}
     * @memberof PresetProOverlaysDTO
     */
    'sport': SportResponseDTO;
}


/**
 * 
 * @export
 * @interface PresetUuid
 */
export interface PresetUuid {
}
/**
 * 
 * @export
 * @interface ProOverlayDTO
 */
export interface ProOverlayDTO {
    /**
     * 
     * @type {HtmlOverlay}
     * @memberof ProOverlayDTO
     */
    'html_overlay'?: HtmlOverlay;
}
/**
 * 
 * @export
 * @interface ProducerDTO
 */
export interface ProducerDTO {
    /**
     * 
     * @type {string}
     * @memberof ProducerDTO
     */
    'hostname': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProducerDTO
     */
    'is_connected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProducerDTO
     */
    'license_ticket_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProducerDTO
     */
    'license_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProducerDTO
     */
    'serial_number': string;
}
/**
 * 
 * @export
 * @interface RemoteSupportDTO
 */
export interface RemoteSupportDTO {
    /**
     * 
     * @type {boolean}
     * @memberof RemoteSupportDTO
     */
    'remote_support': boolean;
}
/**
 * DTO based on the third step of a field configuration
 * @export
 * @interface ResponseDeviceScoreboardDTO
 */
export interface ResponseDeviceScoreboardDTO {
    /**
     * 
     * @type {string}
     * @memberof ResponseDeviceScoreboardDTO
     */
    'input_plugin'?: string;
    /**
     * 
     * @type {Array<ResponseOcrFilesDTO>}
     * @memberof ResponseDeviceScoreboardDTO
     */
    'ocr_files'?: Array<ResponseOcrFilesDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseDeviceScoreboardDTO
     */
    'ocr_files_to_delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ResponseOcrFilesDTO
 */
export interface ResponseOcrFilesDTO {
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrFilesDTO
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrFilesDTO
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseOcrFilesDTO
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface ScoreboardConfiguration
 */
export interface ScoreboardConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ScoreboardConfiguration
     */
    'input_plugin'?: string;
    /**
     * 
     * @type {Array<ResponseOcrFilesDTO>}
     * @memberof ScoreboardConfiguration
     */
    'ocr_files'?: Array<ResponseOcrFilesDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScoreboardConfiguration
     */
    'ocr_files_to_delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ScoreboardConfiguration1
 */
export interface ScoreboardConfiguration1 {
    /**
     * 
     * @type {string}
     * @memberof ScoreboardConfiguration1
     */
    'input_plugin'?: string;
    /**
     * 
     * @type {Array<OcrFilesDTO>}
     * @memberof ScoreboardConfiguration1
     */
    'ocr_files'?: Array<OcrFilesDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScoreboardConfiguration1
     */
    'ocr_files_to_delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SessionDTO
 */
export interface SessionDTO {
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface SetFieldConfig
 */
export interface SetFieldConfig {
    /**
     * 
     * @type {Details1}
     * @memberof SetFieldConfig
     */
    'details'?: Details1;
    /**
     * 
     * @type {ScoreboardConfiguration1}
     * @memberof SetFieldConfig
     */
    'scoreboard_configuration'?: ScoreboardConfiguration1;
}
/**
 * 
 * @export
 * @interface SmartScoreboardTemplate
 */
export interface SmartScoreboardTemplate {
    /**
     * 
     * @type {Image}
     * @memberof SmartScoreboardTemplate
     */
    'image': Image;
}
/**
 * 
 * @export
 * @interface Software
 */
export interface Software {
    /**
     * 
     * @type {string}
     * @memberof Software
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SoftwareAvailableDTO
 */
export interface SoftwareAvailableDTO {
    /**
     * 
     * @type {string}
     * @memberof SoftwareAvailableDTO
     */
    'name': string;
    /**
     * 
     * @type {Version}
     * @memberof SoftwareAvailableDTO
     */
    'version'?: Version;
}
/**
 * 
 * @export
 * @interface SoftwareDTO
 */
export interface SoftwareDTO {
    /**
     * 
     * @type {string}
     * @memberof SoftwareDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SoftwareInstalledDTO
 */
export interface SoftwareInstalledDTO {
    /**
     * 
     * @type {Version}
     * @memberof SoftwareInstalledDTO
     */
    'version'?: Version;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SoftwareNameEnum = {
    Automatictv: 'automatictv',
    Atvedgesupport: 'atvedgesupport',
    Atvedgedeploy: 'atvedgedeploy'
} as const;

export type SoftwareNameEnum = typeof SoftwareNameEnum[keyof typeof SoftwareNameEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirectionOptions = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type SortDirectionOptions = typeof SortDirectionOptions[keyof typeof SortDirectionOptions];


/**
 * DTO based on the second step of a field configuration
 * @export
 * @interface SportCalibration
 */
export interface SportCalibration {
    /**
     * 
     * @type {CalibrationBaseDTO}
     * @memberof SportCalibration
     */
    'calibration': CalibrationBaseDTO;
    /**
     * 
     * @type {SportResponseDTO}
     * @memberof SportCalibration
     */
    'sport': SportResponseDTO;
    /**
     * 
     * @type {string}
     * @memberof SportCalibration
     */
    'sportfield_name': string;
}
/**
 * 
 * @export
 * @interface SportDTO
 */
export interface SportDTO {
    /**
     * 
     * @type {string}
     * @memberof SportDTO
     */
    'icon_name': string;
    /**
     * 
     * @type {string}
     * @memberof SportDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<SportDeviceDTO>}
     * @memberof SportDTO
     */
    'sportfields': Array<SportDeviceDTO>;
    /**
     * 
     * @type {string}
     * @memberof SportDTO
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface SportDeviceDTO
 */
export interface SportDeviceDTO {
    /**
     * 
     * @type {string}
     * @memberof SportDeviceDTO
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof SportDeviceDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SportGroupDTO
 */
export interface SportGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof SportGroupDTO
     */
    'icon_name': string;
    /**
     * 
     * @type {string}
     * @memberof SportGroupDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<SportDTO>}
     * @memberof SportGroupDTO
     */
    'sports': Array<SportDTO>;
}
/**
 * 
 * @export
 * @interface SportResponseDTO
 */
export interface SportResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SportResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SportResponseDTO
     */
    'period_duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof SportResponseDTO
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface StartCalibrationDTO
 */
export interface StartCalibrationDTO {
    /**
     * 
     * @type {string}
     * @memberof StartCalibrationDTO
     */
    'sportfield_name': string;
}
/**
 * 
 * @export
 * @interface StatusResponse
 */
export interface StatusResponse {
    /**
     * 
     * @type {string}
     * @memberof StatusResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface Style
 */
export interface Style {
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TimeZonesEnum = {
    AfricaAbidjan: 'Africa/Abidjan',
    AfricaAccra: 'Africa/Accra',
    AfricaAddisAbaba: 'Africa/Addis_Ababa',
    AfricaAlgiers: 'Africa/Algiers',
    AfricaAsmara: 'Africa/Asmara',
    AfricaBamako: 'Africa/Bamako',
    AfricaBangui: 'Africa/Bangui',
    AfricaBanjul: 'Africa/Banjul',
    AfricaBissau: 'Africa/Bissau',
    AfricaBlantyre: 'Africa/Blantyre',
    AfricaBrazzaville: 'Africa/Brazzaville',
    AfricaBujumbura: 'Africa/Bujumbura',
    AfricaCairo: 'Africa/Cairo',
    AfricaCasablanca: 'Africa/Casablanca',
    AfricaCeuta: 'Africa/Ceuta',
    AfricaConakry: 'Africa/Conakry',
    AfricaDakar: 'Africa/Dakar',
    AfricaDarEsSalaam: 'Africa/Dar_es_Salaam',
    AfricaDjibouti: 'Africa/Djibouti',
    AfricaDouala: 'Africa/Douala',
    AfricaElAaiun: 'Africa/El_Aaiun',
    AfricaFreetown: 'Africa/Freetown',
    AfricaGaborone: 'Africa/Gaborone',
    AfricaHarare: 'Africa/Harare',
    AfricaJohannesburg: 'Africa/Johannesburg',
    AfricaJuba: 'Africa/Juba',
    AfricaKampala: 'Africa/Kampala',
    AfricaKhartoum: 'Africa/Khartoum',
    AfricaKigali: 'Africa/Kigali',
    AfricaKinshasa: 'Africa/Kinshasa',
    AfricaLagos: 'Africa/Lagos',
    AfricaLibreville: 'Africa/Libreville',
    AfricaLome: 'Africa/Lome',
    AfricaLuanda: 'Africa/Luanda',
    AfricaLubumbashi: 'Africa/Lubumbashi',
    AfricaLusaka: 'Africa/Lusaka',
    AfricaMalabo: 'Africa/Malabo',
    AfricaMaputo: 'Africa/Maputo',
    AfricaMaseru: 'Africa/Maseru',
    AfricaMbabane: 'Africa/Mbabane',
    AfricaMogadishu: 'Africa/Mogadishu',
    AfricaMonrovia: 'Africa/Monrovia',
    AfricaNairobi: 'Africa/Nairobi',
    AfricaNdjamena: 'Africa/Ndjamena',
    AfricaNiamey: 'Africa/Niamey',
    AfricaNouakchott: 'Africa/Nouakchott',
    AfricaOuagadougou: 'Africa/Ouagadougou',
    AfricaPortoNovo: 'Africa/Porto-Novo',
    AfricaSaoTome: 'Africa/Sao_Tome',
    AfricaTripoli: 'Africa/Tripoli',
    AfricaTunis: 'Africa/Tunis',
    AfricaWindhoek: 'Africa/Windhoek',
    AmericaAdak: 'America/Adak',
    AmericaAnchorage: 'America/Anchorage',
    AmericaAnguilla: 'America/Anguilla',
    AmericaAntigua: 'America/Antigua',
    AmericaAraguaina: 'America/Araguaina',
    AmericaArgentinaBuenosAires: 'America/Argentina/Buenos_Aires',
    AmericaArgentinaCatamarca: 'America/Argentina/Catamarca',
    AmericaArgentinaCordoba: 'America/Argentina/Cordoba',
    AmericaArgentinaJujuy: 'America/Argentina/Jujuy',
    AmericaArgentinaLaRioja: 'America/Argentina/La_Rioja',
    AmericaArgentinaMendoza: 'America/Argentina/Mendoza',
    AmericaArgentinaRioGallegos: 'America/Argentina/Rio_Gallegos',
    AmericaArgentinaSalta: 'America/Argentina/Salta',
    AmericaArgentinaSanJuan: 'America/Argentina/San_Juan',
    AmericaArgentinaSanLuis: 'America/Argentina/San_Luis',
    AmericaArgentinaTucuman: 'America/Argentina/Tucuman',
    AmericaArgentinaUshuaia: 'America/Argentina/Ushuaia',
    AmericaAruba: 'America/Aruba',
    AmericaAsuncion: 'America/Asuncion',
    AmericaAtikokan: 'America/Atikokan',
    AmericaBahia: 'America/Bahia',
    AmericaBahiaBanderas: 'America/Bahia_Banderas',
    AmericaBarbados: 'America/Barbados',
    AmericaBelem: 'America/Belem',
    AmericaBelize: 'America/Belize',
    AmericaBlancSablon: 'America/Blanc-Sablon',
    AmericaBoaVista: 'America/Boa_Vista',
    AmericaBogota: 'America/Bogota',
    AmericaBoise: 'America/Boise',
    AmericaCambridgeBay: 'America/Cambridge_Bay',
    AmericaCampoGrande: 'America/Campo_Grande',
    AmericaCancun: 'America/Cancun',
    AmericaCaracas: 'America/Caracas',
    AmericaCayenne: 'America/Cayenne',
    AmericaCayman: 'America/Cayman',
    AmericaChicago: 'America/Chicago',
    AmericaChihuahua: 'America/Chihuahua',
    AmericaCiudadJuarez: 'America/Ciudad_Juarez',
    AmericaCostaRica: 'America/Costa_Rica',
    AmericaCreston: 'America/Creston',
    AmericaCuiaba: 'America/Cuiaba',
    AmericaCuracao: 'America/Curacao',
    AmericaDanmarkshavn: 'America/Danmarkshavn',
    AmericaDawson: 'America/Dawson',
    AmericaDawsonCreek: 'America/Dawson_Creek',
    AmericaDenver: 'America/Denver',
    AmericaDetroit: 'America/Detroit',
    AmericaDominica: 'America/Dominica',
    AmericaEdmonton: 'America/Edmonton',
    AmericaEirunepe: 'America/Eirunepe',
    AmericaElSalvador: 'America/El_Salvador',
    AmericaFortNelson: 'America/Fort_Nelson',
    AmericaFortaleza: 'America/Fortaleza',
    AmericaGlaceBay: 'America/Glace_Bay',
    AmericaGooseBay: 'America/Goose_Bay',
    AmericaGrandTurk: 'America/Grand_Turk',
    AmericaGrenada: 'America/Grenada',
    AmericaGuadeloupe: 'America/Guadeloupe',
    AmericaGuatemala: 'America/Guatemala',
    AmericaGuayaquil: 'America/Guayaquil',
    AmericaGuyana: 'America/Guyana',
    AmericaHalifax: 'America/Halifax',
    AmericaHavana: 'America/Havana',
    AmericaHermosillo: 'America/Hermosillo',
    AmericaIndianaIndianapolis: 'America/Indiana/Indianapolis',
    AmericaIndianaKnox: 'America/Indiana/Knox',
    AmericaIndianaMarengo: 'America/Indiana/Marengo',
    AmericaIndianaPetersburg: 'America/Indiana/Petersburg',
    AmericaIndianaTellCity: 'America/Indiana/Tell_City',
    AmericaIndianaVevay: 'America/Indiana/Vevay',
    AmericaIndianaVincennes: 'America/Indiana/Vincennes',
    AmericaIndianaWinamac: 'America/Indiana/Winamac',
    AmericaInuvik: 'America/Inuvik',
    AmericaIqaluit: 'America/Iqaluit',
    AmericaJamaica: 'America/Jamaica',
    AmericaJuneau: 'America/Juneau',
    AmericaKentuckyLouisville: 'America/Kentucky/Louisville',
    AmericaKentuckyMonticello: 'America/Kentucky/Monticello',
    AmericaKralendijk: 'America/Kralendijk',
    AmericaLaPaz: 'America/La_Paz',
    AmericaLima: 'America/Lima',
    AmericaLosAngeles: 'America/Los_Angeles',
    AmericaLowerPrinces: 'America/Lower_Princes',
    AmericaMaceio: 'America/Maceio',
    AmericaManagua: 'America/Managua',
    AmericaManaus: 'America/Manaus',
    AmericaMarigot: 'America/Marigot',
    AmericaMartinique: 'America/Martinique',
    AmericaMatamoros: 'America/Matamoros',
    AmericaMazatlan: 'America/Mazatlan',
    AmericaMenominee: 'America/Menominee',
    AmericaMerida: 'America/Merida',
    AmericaMetlakatla: 'America/Metlakatla',
    AmericaMexicoCity: 'America/Mexico_City',
    AmericaMiquelon: 'America/Miquelon',
    AmericaMoncton: 'America/Moncton',
    AmericaMonterrey: 'America/Monterrey',
    AmericaMontevideo: 'America/Montevideo',
    AmericaMontserrat: 'America/Montserrat',
    AmericaNassau: 'America/Nassau',
    AmericaNewYork: 'America/New_York',
    AmericaNome: 'America/Nome',
    AmericaNoronha: 'America/Noronha',
    AmericaNorthDakotaBeulah: 'America/North_Dakota/Beulah',
    AmericaNorthDakotaCenter: 'America/North_Dakota/Center',
    AmericaNorthDakotaNewSalem: 'America/North_Dakota/New_Salem',
    AmericaNuuk: 'America/Nuuk',
    AmericaOjinaga: 'America/Ojinaga',
    AmericaPanama: 'America/Panama',
    AmericaParamaribo: 'America/Paramaribo',
    AmericaPhoenix: 'America/Phoenix',
    AmericaPortAuPrince: 'America/Port-au-Prince',
    AmericaPortOfSpain: 'America/Port_of_Spain',
    AmericaPortoVelho: 'America/Porto_Velho',
    AmericaPuertoRico: 'America/Puerto_Rico',
    AmericaPuntaArenas: 'America/Punta_Arenas',
    AmericaRankinInlet: 'America/Rankin_Inlet',
    AmericaRecife: 'America/Recife',
    AmericaRegina: 'America/Regina',
    AmericaResolute: 'America/Resolute',
    AmericaRioBranco: 'America/Rio_Branco',
    AmericaSantarem: 'America/Santarem',
    AmericaSantiago: 'America/Santiago',
    AmericaSantoDomingo: 'America/Santo_Domingo',
    AmericaSaoPaulo: 'America/Sao_Paulo',
    AmericaScoresbysund: 'America/Scoresbysund',
    AmericaSitka: 'America/Sitka',
    AmericaStBarthelemy: 'America/St_Barthelemy',
    AmericaStJohns: 'America/St_Johns',
    AmericaStKitts: 'America/St_Kitts',
    AmericaStLucia: 'America/St_Lucia',
    AmericaStThomas: 'America/St_Thomas',
    AmericaStVincent: 'America/St_Vincent',
    AmericaSwiftCurrent: 'America/Swift_Current',
    AmericaTegucigalpa: 'America/Tegucigalpa',
    AmericaThule: 'America/Thule',
    AmericaTijuana: 'America/Tijuana',
    AmericaToronto: 'America/Toronto',
    AmericaTortola: 'America/Tortola',
    AmericaVancouver: 'America/Vancouver',
    AmericaWhitehorse: 'America/Whitehorse',
    AmericaWinnipeg: 'America/Winnipeg',
    AmericaYakutat: 'America/Yakutat',
    AmericaYellowknife: 'America/Yellowknife',
    AntarcticaCasey: 'Antarctica/Casey',
    AntarcticaDavis: 'Antarctica/Davis',
    AntarcticaDumontDUrville: 'Antarctica/DumontDUrville',
    AntarcticaMacquarie: 'Antarctica/Macquarie',
    AntarcticaMawson: 'Antarctica/Mawson',
    AntarcticaMcMurdo: 'Antarctica/McMurdo',
    AntarcticaPalmer: 'Antarctica/Palmer',
    AntarcticaRothera: 'Antarctica/Rothera',
    AntarcticaSyowa: 'Antarctica/Syowa',
    AntarcticaTroll: 'Antarctica/Troll',
    AntarcticaVostok: 'Antarctica/Vostok',
    ArcticLongyearbyen: 'Arctic/Longyearbyen',
    AsiaAden: 'Asia/Aden',
    AsiaAlmaty: 'Asia/Almaty',
    AsiaAmman: 'Asia/Amman',
    AsiaAnadyr: 'Asia/Anadyr',
    AsiaAqtau: 'Asia/Aqtau',
    AsiaAqtobe: 'Asia/Aqtobe',
    AsiaAshgabat: 'Asia/Ashgabat',
    AsiaAtyrau: 'Asia/Atyrau',
    AsiaBaghdad: 'Asia/Baghdad',
    AsiaBahrain: 'Asia/Bahrain',
    AsiaBaku: 'Asia/Baku',
    AsiaBangkok: 'Asia/Bangkok',
    AsiaBarnaul: 'Asia/Barnaul',
    AsiaBeirut: 'Asia/Beirut',
    AsiaBishkek: 'Asia/Bishkek',
    AsiaBrunei: 'Asia/Brunei',
    AsiaChita: 'Asia/Chita',
    AsiaChoibalsan: 'Asia/Choibalsan',
    AsiaColombo: 'Asia/Colombo',
    AsiaDamascus: 'Asia/Damascus',
    AsiaDhaka: 'Asia/Dhaka',
    AsiaDili: 'Asia/Dili',
    AsiaDubai: 'Asia/Dubai',
    AsiaDushanbe: 'Asia/Dushanbe',
    AsiaFamagusta: 'Asia/Famagusta',
    AsiaGaza: 'Asia/Gaza',
    AsiaHebron: 'Asia/Hebron',
    AsiaHoChiMinh: 'Asia/Ho_Chi_Minh',
    AsiaHongKong: 'Asia/Hong_Kong',
    AsiaHovd: 'Asia/Hovd',
    AsiaIrkutsk: 'Asia/Irkutsk',
    AsiaJakarta: 'Asia/Jakarta',
    AsiaJayapura: 'Asia/Jayapura',
    AsiaJerusalem: 'Asia/Jerusalem',
    AsiaKabul: 'Asia/Kabul',
    AsiaKamchatka: 'Asia/Kamchatka',
    AsiaKarachi: 'Asia/Karachi',
    AsiaKathmandu: 'Asia/Kathmandu',
    AsiaKhandyga: 'Asia/Khandyga',
    AsiaKolkata: 'Asia/Kolkata',
    AsiaKrasnoyarsk: 'Asia/Krasnoyarsk',
    AsiaKualaLumpur: 'Asia/Kuala_Lumpur',
    AsiaKuching: 'Asia/Kuching',
    AsiaKuwait: 'Asia/Kuwait',
    AsiaMacau: 'Asia/Macau',
    AsiaMagadan: 'Asia/Magadan',
    AsiaMakassar: 'Asia/Makassar',
    AsiaManila: 'Asia/Manila',
    AsiaMuscat: 'Asia/Muscat',
    AsiaNicosia: 'Asia/Nicosia',
    AsiaNovokuznetsk: 'Asia/Novokuznetsk',
    AsiaNovosibirsk: 'Asia/Novosibirsk',
    AsiaOmsk: 'Asia/Omsk',
    AsiaOral: 'Asia/Oral',
    AsiaPhnomPenh: 'Asia/Phnom_Penh',
    AsiaPontianak: 'Asia/Pontianak',
    AsiaPyongyang: 'Asia/Pyongyang',
    AsiaQatar: 'Asia/Qatar',
    AsiaQostanay: 'Asia/Qostanay',
    AsiaQyzylorda: 'Asia/Qyzylorda',
    AsiaRiyadh: 'Asia/Riyadh',
    AsiaSakhalin: 'Asia/Sakhalin',
    AsiaSamarkand: 'Asia/Samarkand',
    AsiaSeoul: 'Asia/Seoul',
    AsiaShanghai: 'Asia/Shanghai',
    AsiaSingapore: 'Asia/Singapore',
    AsiaSrednekolymsk: 'Asia/Srednekolymsk',
    AsiaTaipei: 'Asia/Taipei',
    AsiaTashkent: 'Asia/Tashkent',
    AsiaTbilisi: 'Asia/Tbilisi',
    AsiaTehran: 'Asia/Tehran',
    AsiaThimphu: 'Asia/Thimphu',
    AsiaTokyo: 'Asia/Tokyo',
    AsiaTomsk: 'Asia/Tomsk',
    AsiaUlaanbaatar: 'Asia/Ulaanbaatar',
    AsiaUrumqi: 'Asia/Urumqi',
    AsiaUstNera: 'Asia/Ust-Nera',
    AsiaVientiane: 'Asia/Vientiane',
    AsiaVladivostok: 'Asia/Vladivostok',
    AsiaYakutsk: 'Asia/Yakutsk',
    AsiaYangon: 'Asia/Yangon',
    AsiaYekaterinburg: 'Asia/Yekaterinburg',
    AsiaYerevan: 'Asia/Yerevan',
    AtlanticAzores: 'Atlantic/Azores',
    AtlanticBermuda: 'Atlantic/Bermuda',
    AtlanticCanary: 'Atlantic/Canary',
    AtlanticCapeVerde: 'Atlantic/Cape_Verde',
    AtlanticFaroe: 'Atlantic/Faroe',
    AtlanticMadeira: 'Atlantic/Madeira',
    AtlanticReykjavik: 'Atlantic/Reykjavik',
    AtlanticSouthGeorgia: 'Atlantic/South_Georgia',
    AtlanticStHelena: 'Atlantic/St_Helena',
    AtlanticStanley: 'Atlantic/Stanley',
    AustraliaAdelaide: 'Australia/Adelaide',
    AustraliaBrisbane: 'Australia/Brisbane',
    AustraliaBrokenHill: 'Australia/Broken_Hill',
    AustraliaDarwin: 'Australia/Darwin',
    AustraliaEucla: 'Australia/Eucla',
    AustraliaHobart: 'Australia/Hobart',
    AustraliaLindeman: 'Australia/Lindeman',
    AustraliaLordHowe: 'Australia/Lord_Howe',
    AustraliaMelbourne: 'Australia/Melbourne',
    AustraliaPerth: 'Australia/Perth',
    AustraliaSydney: 'Australia/Sydney',
    CanadaAtlantic: 'Canada/Atlantic',
    CanadaCentral: 'Canada/Central',
    CanadaEastern: 'Canada/Eastern',
    CanadaMountain: 'Canada/Mountain',
    CanadaNewfoundland: 'Canada/Newfoundland',
    CanadaPacific: 'Canada/Pacific',
    EuropeAmsterdam: 'Europe/Amsterdam',
    EuropeAndorra: 'Europe/Andorra',
    EuropeAstrakhan: 'Europe/Astrakhan',
    EuropeAthens: 'Europe/Athens',
    EuropeBelgrade: 'Europe/Belgrade',
    EuropeBerlin: 'Europe/Berlin',
    EuropeBratislava: 'Europe/Bratislava',
    EuropeBrussels: 'Europe/Brussels',
    EuropeBucharest: 'Europe/Bucharest',
    EuropeBudapest: 'Europe/Budapest',
    EuropeBusingen: 'Europe/Busingen',
    EuropeChisinau: 'Europe/Chisinau',
    EuropeCopenhagen: 'Europe/Copenhagen',
    EuropeDublin: 'Europe/Dublin',
    EuropeGibraltar: 'Europe/Gibraltar',
    EuropeGuernsey: 'Europe/Guernsey',
    EuropeHelsinki: 'Europe/Helsinki',
    EuropeIsleOfMan: 'Europe/Isle_of_Man',
    EuropeIstanbul: 'Europe/Istanbul',
    EuropeJersey: 'Europe/Jersey',
    EuropeKaliningrad: 'Europe/Kaliningrad',
    EuropeKirov: 'Europe/Kirov',
    EuropeKyiv: 'Europe/Kyiv',
    EuropeLisbon: 'Europe/Lisbon',
    EuropeLjubljana: 'Europe/Ljubljana',
    EuropeLondon: 'Europe/London',
    EuropeLuxembourg: 'Europe/Luxembourg',
    EuropeMadrid: 'Europe/Madrid',
    EuropeMalta: 'Europe/Malta',
    EuropeMariehamn: 'Europe/Mariehamn',
    EuropeMinsk: 'Europe/Minsk',
    EuropeMonaco: 'Europe/Monaco',
    EuropeMoscow: 'Europe/Moscow',
    EuropeOslo: 'Europe/Oslo',
    EuropeParis: 'Europe/Paris',
    EuropePodgorica: 'Europe/Podgorica',
    EuropePrague: 'Europe/Prague',
    EuropeRiga: 'Europe/Riga',
    EuropeRome: 'Europe/Rome',
    EuropeSamara: 'Europe/Samara',
    EuropeSanMarino: 'Europe/San_Marino',
    EuropeSarajevo: 'Europe/Sarajevo',
    EuropeSaratov: 'Europe/Saratov',
    EuropeSimferopol: 'Europe/Simferopol',
    EuropeSkopje: 'Europe/Skopje',
    EuropeSofia: 'Europe/Sofia',
    EuropeStockholm: 'Europe/Stockholm',
    EuropeTallinn: 'Europe/Tallinn',
    EuropeTirane: 'Europe/Tirane',
    EuropeUlyanovsk: 'Europe/Ulyanovsk',
    EuropeVaduz: 'Europe/Vaduz',
    EuropeVatican: 'Europe/Vatican',
    EuropeVienna: 'Europe/Vienna',
    EuropeVilnius: 'Europe/Vilnius',
    EuropeVolgograd: 'Europe/Volgograd',
    EuropeWarsaw: 'Europe/Warsaw',
    EuropeZagreb: 'Europe/Zagreb',
    EuropeZurich: 'Europe/Zurich',
    Gmt: 'GMT',
    IndianAntananarivo: 'Indian/Antananarivo',
    IndianChagos: 'Indian/Chagos',
    IndianChristmas: 'Indian/Christmas',
    IndianCocos: 'Indian/Cocos',
    IndianComoro: 'Indian/Comoro',
    IndianKerguelen: 'Indian/Kerguelen',
    IndianMahe: 'Indian/Mahe',
    IndianMaldives: 'Indian/Maldives',
    IndianMauritius: 'Indian/Mauritius',
    IndianMayotte: 'Indian/Mayotte',
    IndianReunion: 'Indian/Reunion',
    PacificApia: 'Pacific/Apia',
    PacificAuckland: 'Pacific/Auckland',
    PacificBougainville: 'Pacific/Bougainville',
    PacificChatham: 'Pacific/Chatham',
    PacificChuuk: 'Pacific/Chuuk',
    PacificEaster: 'Pacific/Easter',
    PacificEfate: 'Pacific/Efate',
    PacificFakaofo: 'Pacific/Fakaofo',
    PacificFiji: 'Pacific/Fiji',
    PacificFunafuti: 'Pacific/Funafuti',
    PacificGalapagos: 'Pacific/Galapagos',
    PacificGambier: 'Pacific/Gambier',
    PacificGuadalcanal: 'Pacific/Guadalcanal',
    PacificGuam: 'Pacific/Guam',
    PacificHonolulu: 'Pacific/Honolulu',
    PacificKanton: 'Pacific/Kanton',
    PacificKiritimati: 'Pacific/Kiritimati',
    PacificKosrae: 'Pacific/Kosrae',
    PacificKwajalein: 'Pacific/Kwajalein',
    PacificMajuro: 'Pacific/Majuro',
    PacificMarquesas: 'Pacific/Marquesas',
    PacificMidway: 'Pacific/Midway',
    PacificNauru: 'Pacific/Nauru',
    PacificNiue: 'Pacific/Niue',
    PacificNorfolk: 'Pacific/Norfolk',
    PacificNoumea: 'Pacific/Noumea',
    PacificPagoPago: 'Pacific/Pago_Pago',
    PacificPalau: 'Pacific/Palau',
    PacificPitcairn: 'Pacific/Pitcairn',
    PacificPohnpei: 'Pacific/Pohnpei',
    PacificPortMoresby: 'Pacific/Port_Moresby',
    PacificRarotonga: 'Pacific/Rarotonga',
    PacificSaipan: 'Pacific/Saipan',
    PacificTahiti: 'Pacific/Tahiti',
    PacificTarawa: 'Pacific/Tarawa',
    PacificTongatapu: 'Pacific/Tongatapu',
    PacificWake: 'Pacific/Wake',
    PacificWallis: 'Pacific/Wallis',
    UsAlaska: 'US/Alaska',
    UsArizona: 'US/Arizona',
    UsCentral: 'US/Central',
    UsEastern: 'US/Eastern',
    UsHawaii: 'US/Hawaii',
    UsMountain: 'US/Mountain',
    UsPacific: 'US/Pacific',
    Utc: 'UTC'
} as const;

export type TimeZonesEnum = typeof TimeZonesEnum[keyof typeof TimeZonesEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const TypeEnum = {
    Input: 'input',
    Output: 'output'
} as const;

export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const UiHintEnum = {
    ComboBox: 'ComboBox',
    Slider: 'Slider',
    Toogle: 'Toogle'
} as const;

export type UiHintEnum = typeof UiHintEnum[keyof typeof UiHintEnum];


/**
 * 
 * @export
 * @interface Upgrade
 */
export interface Upgrade {
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<LocationInner>}
     * @memberof ValidationError
     */
    'loc': Array<LocationInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Value
 */
export interface Value {
}
/**
 * 
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * 
     * @type {Software}
     * @memberof Version
     */
    'software'?: Software;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'version_number'?: string;
}
/**
 * 
 * @export
 * @interface VersionDTO
 */
export interface VersionDTO {
    /**
     * 
     * @type {Software}
     * @memberof VersionDTO
     */
    'software'?: Software;
    /**
     * 
     * @type {string}
     * @memberof VersionDTO
     */
    'version_number'?: string;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auth Url
         * @param {string} state Random hexadecimal parameter
         * @param {string} codeChallenge BASE64-URL-encoded string of the SHA256 hash of the code verifier
         * @param {string} redirectUri Redirect uri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUrlAuthUrlGet: async (state: string, codeChallenge: string, redirectUri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('authUrlAuthUrlGet', 'state', state)
            // verify required parameter 'codeChallenge' is not null or undefined
            assertParamExists('authUrlAuthUrlGet', 'codeChallenge', codeChallenge)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('authUrlAuthUrlGet', 'redirectUri', redirectUri)
            const localVarPath = `/auth/url/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (codeChallenge !== undefined) {
                localVarQueryParameter['code_challenge'] = codeChallenge;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is only used to test the API from Swagger UI, the frontend application will use the Authorization Code + PKCE Flow.
         * @summary Login With Password
         * @param {string} password 
         * @param {string} username 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithPasswordAuthLoginPost: async (password: string, username: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginWithPasswordAuthLoginPost', 'password', password)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginWithPasswordAuthLoginPost', 'username', username)
            const localVarPath = `/auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (clientSecret !== undefined) {
                localVarQueryParameter['client_secret'] = clientSecret;
            }


            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuthLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token From Code
         * @param {string} redirectUri Redirect uri
         * @param {string} code Random hexadecimal
         * @param {string} codeVerifier Cryptographically random string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenFromCodeAuthTokenPost: async (redirectUri: string, code: string, codeVerifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('tokenFromCodeAuthTokenPost', 'redirectUri', redirectUri)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('tokenFromCodeAuthTokenPost', 'code', code)
            // verify required parameter 'codeVerifier' is not null or undefined
            assertParamExists('tokenFromCodeAuthTokenPost', 'codeVerifier', codeVerifier)
            const localVarPath = `/auth/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (codeVerifier !== undefined) {
                localVarQueryParameter['code_verifier'] = codeVerifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token From Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenFromRefreshTokenAuthRefreshTokenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auth Url
         * @param {string} state Random hexadecimal parameter
         * @param {string} codeChallenge BASE64-URL-encoded string of the SHA256 hash of the code verifier
         * @param {string} redirectUri Redirect uri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUrlAuthUrlGet(state: string, codeChallenge: string, redirectUri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUrlAuthUrlGet(state, codeChallenge, redirectUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is only used to test the API from Swagger UI, the frontend application will use the Authorization Code + PKCE Flow.
         * @summary Login With Password
         * @param {string} password 
         * @param {string} username 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithPasswordAuthLoginPost(password: string, username: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithPasswordAuthLoginPost(password, username, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutAuthLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutAuthLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token From Code
         * @param {string} redirectUri Redirect uri
         * @param {string} code Random hexadecimal
         * @param {string} codeVerifier Cryptographically random string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenFromCodeAuthTokenPost(redirectUri: string, code: string, codeVerifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenFromCodeAuthTokenPost(redirectUri, code, codeVerifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token From Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenFromRefreshTokenAuthRefreshTokenPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenFromRefreshTokenAuthRefreshTokenPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Auth Url
         * @param {string} state Random hexadecimal parameter
         * @param {string} codeChallenge BASE64-URL-encoded string of the SHA256 hash of the code verifier
         * @param {string} redirectUri Redirect uri
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUrlAuthUrlGet(state: string, codeChallenge: string, redirectUri: string, options?: any): AxiosPromise<void> {
            return localVarFp.authUrlAuthUrlGet(state, codeChallenge, redirectUri, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is only used to test the API from Swagger UI, the frontend application will use the Authorization Code + PKCE Flow.
         * @summary Login With Password
         * @param {string} password 
         * @param {string} username 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithPasswordAuthLoginPost(password: string, username: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginWithPasswordAuthLoginPost(password, username, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuthLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.logoutAuthLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token From Code
         * @param {string} redirectUri Redirect uri
         * @param {string} code Random hexadecimal
         * @param {string} codeVerifier Cryptographically random string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenFromCodeAuthTokenPost(redirectUri: string, code: string, codeVerifier: string, options?: any): AxiosPromise<void> {
            return localVarFp.tokenFromCodeAuthTokenPost(redirectUri, code, codeVerifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token From Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenFromRefreshTokenAuthRefreshTokenPost(options?: any): AxiosPromise<void> {
            return localVarFp.tokenFromRefreshTokenAuthRefreshTokenPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Auth Url
     * @param {string} state Random hexadecimal parameter
     * @param {string} codeChallenge BASE64-URL-encoded string of the SHA256 hash of the code verifier
     * @param {string} redirectUri Redirect uri
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authUrlAuthUrlGet(state: string, codeChallenge: string, redirectUri: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authUrlAuthUrlGet(state, codeChallenge, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is only used to test the API from Swagger UI, the frontend application will use the Authorization Code + PKCE Flow.
     * @summary Login With Password
     * @param {string} password 
     * @param {string} username 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public loginWithPasswordAuthLoginPost(password: string, username: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).loginWithPasswordAuthLoginPost(password, username, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logoutAuthLogoutPost(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logoutAuthLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token From Code
     * @param {string} redirectUri Redirect uri
     * @param {string} code Random hexadecimal
     * @param {string} codeVerifier Cryptographically random string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public tokenFromCodeAuthTokenPost(redirectUri: string, code: string, codeVerifier: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).tokenFromCodeAuthTokenPost(redirectUri, code, codeVerifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token From Refresh Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public tokenFromRefreshTokenAuthRefreshTokenPost(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).tokenFromRefreshTokenAuthRefreshTokenPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalibrationApi - axios parameter creator
 * @export
 */
export const CalibrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Calibrate Sport
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {CalibrationDTO} calibrationDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch: async (deviceUuid: string, sportUuid: string, calibrationDTO: CalibrationDTO, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch', 'sportUuid', sportUuid)
            // verify required parameter 'calibrationDTO' is not null or undefined
            assertParamExists('calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch', 'calibrationDTO', calibrationDTO)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/calibrate`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calibrationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Delete Calibrated Sport
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete: async (deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
         * @summary Get Camera Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {number} cameraId 
         * @param {boolean} [onlyImage] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet: async (deviceUuid: string, sportUuid: string, cameraId: number, onlyImage?: boolean, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet', 'sportUuid', sportUuid)
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet', 'cameraId', cameraId)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/cameras/{camera_id}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)))
                .replace(`{${"camera_id"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (onlyImage !== undefined) {
                localVarQueryParameter['only_image'] = onlyImage;
            }

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the panorama calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
         * @summary Get Panorama Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {boolean} [onlyImage] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet: async (deviceUuid: string, sportUuid: string, onlyImage?: boolean, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/panorama/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (onlyImage !== undefined) {
                localVarQueryParameter['only_image'] = onlyImage;
            }

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Save Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost: async (deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to prepare the camera for the calibration, should be called when clicking on \'Start Calibration\' button. To make this, we need the information from the 1st step of the field configuration
         * @summary Start Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {StartCalibrationDTO} startCalibrationDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost: async (deviceUuid: string, sportUuid: string, startCalibrationDTO: StartCalibrationDTO, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost', 'sportUuid', sportUuid)
            // verify required parameter 'startCalibrationDTO' is not null or undefined
            assertParamExists('startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost', 'startCalibrationDTO', startCalibrationDTO)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/start`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startCalibrationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to unload the calibration, should be called when clicking on \'back\' button.
         * @summary Unload Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost: async (deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/calibration/sports/{sport_uuid}/unload`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalibrationApi - functional programming interface
 * @export
 */
export const CalibrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalibrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Calibrate Sport
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {CalibrationDTO} calibrationDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch(deviceUuid: string, sportUuid: string, calibrationDTO: CalibrationDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PanoramaCalibrationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch(deviceUuid, sportUuid, calibrationDTO, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Delete Calibrated Sport
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(deviceUuid, sportUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the current calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
         * @summary Get Camera Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {number} cameraId 
         * @param {boolean} [onlyImage] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet(deviceUuid: string, sportUuid: string, cameraId: number, onlyImage?: boolean, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraCalibrationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet(deviceUuid, sportUuid, cameraId, onlyImage, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the panorama calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
         * @summary Get Panorama Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {boolean} [onlyImage] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet(deviceUuid: string, sportUuid: string, onlyImage?: boolean, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraCalibrationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet(deviceUuid, sportUuid, onlyImage, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Save Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost(deviceUuid, sportUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to prepare the camera for the calibration, should be called when clicking on \'Start Calibration\' button. To make this, we need the information from the 1st step of the field configuration
         * @summary Start Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {StartCalibrationDTO} startCalibrationDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(deviceUuid: string, sportUuid: string, startCalibrationDTO: StartCalibrationDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(deviceUuid, sportUuid, startCalibrationDTO, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to unload the calibration, should be called when clicking on \'back\' button.
         * @summary Unload Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost(deviceUuid, sportUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalibrationApi - factory interface
 * @export
 */
export const CalibrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalibrationApiFp(configuration)
    return {
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Calibrate Sport
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {CalibrationDTO} calibrationDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch(deviceUuid: string, sportUuid: string, calibrationDTO: CalibrationDTO, xWizardSessionToken?: string, options?: any): AxiosPromise<PanoramaCalibrationDTO> {
            return localVarFp.calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch(deviceUuid, sportUuid, calibrationDTO, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Delete Calibrated Sport
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
         * @summary Get Camera Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {number} cameraId 
         * @param {boolean} [onlyImage] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet(deviceUuid: string, sportUuid: string, cameraId: number, onlyImage?: boolean, xWizardSessionToken?: string, options?: any): AxiosPromise<CameraCalibrationDTO> {
            return localVarFp.getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet(deviceUuid, sportUuid, cameraId, onlyImage, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the panorama calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
         * @summary Get Panorama Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {boolean} [onlyImage] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet(deviceUuid: string, sportUuid: string, onlyImage?: boolean, xWizardSessionToken?: string, options?: any): AxiosPromise<CameraCalibrationDTO> {
            return localVarFp.getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet(deviceUuid, sportUuid, onlyImage, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Send calibration points to a device, and return the current calibration with an image
         * @summary Save Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to prepare the camera for the calibration, should be called when clicking on \'Start Calibration\' button. To make this, we need the information from the 1st step of the field configuration
         * @summary Start Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {StartCalibrationDTO} startCalibrationDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(deviceUuid: string, sportUuid: string, startCalibrationDTO: StartCalibrationDTO, xWizardSessionToken?: string, options?: any): AxiosPromise<StatusResponse> {
            return localVarFp.startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(deviceUuid, sportUuid, startCalibrationDTO, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to unload the calibration, should be called when clicking on \'back\' button.
         * @summary Unload Calibration
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<StatusResponse> {
            return localVarFp.unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalibrationApi - object-oriented interface
 * @export
 * @class CalibrationApi
 * @extends {BaseAPI}
 */
export class CalibrationApi extends BaseAPI {
    /**
     * Send calibration points to a device, and return the current calibration with an image
     * @summary Calibrate Sport
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {CalibrationDTO} calibrationDTO 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch(deviceUuid: string, sportUuid: string, calibrationDTO: CalibrationDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).calibrateSportDevicesDeviceUuidCalibrationSportsSportUuidCalibratePatch(deviceUuid, sportUuid, calibrationDTO, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send calibration points to a device, and return the current calibration with an image
     * @summary Delete Calibrated Sport
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).deleteCalibratedSportDevicesDeviceUuidCalibrationSportsSportUuidDelete(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
     * @summary Get Camera Calibration
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {number} cameraId 
     * @param {boolean} [onlyImage] 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet(deviceUuid: string, sportUuid: string, cameraId: number, onlyImage?: boolean, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).getCameraCalibrationDevicesDeviceUuidCalibrationSportsSportUuidCamerasCameraIdGet(deviceUuid, sportUuid, cameraId, onlyImage, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the panorama calibration of specific sportfield with an image. This endpoint will load the sport into the producer before asking for the image
     * @summary Get Panorama Calibration
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {boolean} [onlyImage] 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet(deviceUuid: string, sportUuid: string, onlyImage?: boolean, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).getPanoramaCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPanoramaGet(deviceUuid, sportUuid, onlyImage, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send calibration points to a device, and return the current calibration with an image
     * @summary Save Calibration
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).saveCalibrationDevicesDeviceUuidCalibrationSportsSportUuidPost(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to prepare the camera for the calibration, should be called when clicking on \'Start Calibration\' button. To make this, we need the information from the 1st step of the field configuration
     * @summary Start Calibration
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {StartCalibrationDTO} startCalibrationDTO 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(deviceUuid: string, sportUuid: string, startCalibrationDTO: StartCalibrationDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).startCalibrationDevicesDeviceUuidCalibrationSportsSportUuidStartPost(deviceUuid, sportUuid, startCalibrationDTO, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to unload the calibration, should be called when clicking on \'back\' button.
     * @summary Unload Calibration
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalibrationApi
     */
    public unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return CalibrationApiFp(this.configuration).unloadCalibrationDevicesDeviceUuidCalibrationSportsSportUuidUnloadPost(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CamerasApi - axios parameter creator
 * @export
 */
export const CamerasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Camera Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraTypesOptionsCameraTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/camera_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CamerasApi - functional programming interface
 * @export
 */
export const CamerasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CamerasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Camera Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraTypesOptionsCameraTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraTypesOptionsCameraTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CamerasApi - factory interface
 * @export
 */
export const CamerasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CamerasApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Camera Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraTypesOptionsCameraTypesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCameraTypesOptionsCameraTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CamerasApi - object-oriented interface
 * @export
 * @class CamerasApi
 * @extends {BaseAPI}
 */
export class CamerasApi extends BaseAPI {
    /**
     * 
     * @summary Get Camera Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CamerasApi
     */
    public getCameraTypesOptionsCameraTypesGet(options?: AxiosRequestConfig) {
        return CamerasApiFp(this.configuration).getCameraTypesOptionsCameraTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootHealthzGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthz/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootHealthzGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootHealthzGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootHealthzGet(options?: any): AxiosPromise<any> {
            return localVarFp.rootHealthzGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootHealthzGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootHealthzGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceConfigurationApi - axios parameter creator
 * @export
 */
export const DeviceConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clear Wizard Session
         * @param {string} deviceUuid 
         * @param {string} xExpiryTimestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete: async (deviceUuid: string, xExpiryTimestamp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete', 'deviceUuid', deviceUuid)
            // verify required parameter 'xExpiryTimestamp' is not null or undefined
            assertParamExists('clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete', 'xExpiryTimestamp', xExpiryTimestamp)
            const localVarPath = `/devices/{device_uuid}/configuration/session/clear/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication InternalTokenHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Internal-Token", configuration)

            if (xExpiryTimestamp != null) {
                localVarHeaderParameter['x-expiry-timestamp'] = String(xExpiryTimestamp);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldConfigurationDevicesDeviceUuidConfigurationGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFieldConfigurationDevicesDeviceUuidConfigurationGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/configuration/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditCameraDetailsDTO} editCameraDetailsDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost: async (deviceUuid: string, editCameraDetailsDTO: EditCameraDetailsDTO, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'editCameraDetailsDTO' is not null or undefined
            assertParamExists('setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost', 'editCameraDetailsDTO', editCameraDetailsDTO)
            const localVarPath = `/devices/{device_uuid}/configuration/camera_config/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCameraDetailsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {Payload} payload 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldConfigurationDevicesDeviceUuidConfigurationPost: async (deviceUuid: string, payload: Payload, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('setFieldConfigurationDevicesDeviceUuidConfigurationPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('setFieldConfigurationDevicesDeviceUuidConfigurationPost', 'payload', payload)
            const localVarPath = `/devices/{device_uuid}/configuration/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Wizard Session
         * @param {string} deviceUuid 
         * @param {boolean} [force] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWizardSessionDevicesDeviceUuidConfigurationSessionPost: async (deviceUuid: string, force?: boolean, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('startWizardSessionDevicesDeviceUuidConfigurationSessionPost', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/configuration/session/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop Wizard Session
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/configuration/session/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceConfigurationApi - functional programming interface
 * @export
 */
export const DeviceConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clear Wizard Session
         * @param {string} deviceUuid 
         * @param {string} xExpiryTimestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete(deviceUuid: string, xExpiryTimestamp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete(deviceUuid, xExpiryTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldConfigurationDevicesDeviceUuidConfigurationGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldConfigurationDevicesDeviceUuidConfigurationGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditCameraDetailsDTO} editCameraDetailsDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editCameraDetailsDTO: EditCameraDetailsDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editCameraDetailsDTO, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {Payload} payload 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFieldConfigurationDevicesDeviceUuidConfigurationPost(deviceUuid: string, payload: Payload, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFieldConfigurationDevicesDeviceUuidConfigurationPost(deviceUuid, payload, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start Wizard Session
         * @param {string} deviceUuid 
         * @param {boolean} [force] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startWizardSessionDevicesDeviceUuidConfigurationSessionPost(deviceUuid: string, force?: boolean, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startWizardSessionDevicesDeviceUuidConfigurationSessionPost(deviceUuid, force, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop Wizard Session
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceConfigurationApi - factory interface
 * @export
 */
export const DeviceConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Clear Wizard Session
         * @param {string} deviceUuid 
         * @param {string} xExpiryTimestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete(deviceUuid: string, xExpiryTimestamp: string, options?: any): AxiosPromise<void> {
            return localVarFp.clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete(deviceUuid, xExpiryTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldConfigurationDevicesDeviceUuidConfigurationGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<DeviceConfigurationDTO> {
            return localVarFp.getFieldConfigurationDevicesDeviceUuidConfigurationGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditCameraDetailsDTO} editCameraDetailsDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editCameraDetailsDTO: EditCameraDetailsDTO, xWizardSessionToken?: string, options?: any): AxiosPromise<DefaultResponseDTO> {
            return localVarFp.setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editCameraDetailsDTO, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {Payload} payload 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldConfigurationDevicesDeviceUuidConfigurationPost(deviceUuid: string, payload: Payload, xWizardSessionToken?: string, options?: any): AxiosPromise<DeviceConfigurationDTO> {
            return localVarFp.setFieldConfigurationDevicesDeviceUuidConfigurationPost(deviceUuid, payload, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Wizard Session
         * @param {string} deviceUuid 
         * @param {boolean} [force] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWizardSessionDevicesDeviceUuidConfigurationSessionPost(deviceUuid: string, force?: boolean, xWizardSessionToken?: string, options?: any): AxiosPromise<SessionDTO> {
            return localVarFp.startWizardSessionDevicesDeviceUuidConfigurationSessionPost(deviceUuid, force, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop Wizard Session
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceConfigurationApi - object-oriented interface
 * @export
 * @class DeviceConfigurationApi
 * @extends {BaseAPI}
 */
export class DeviceConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Clear Wizard Session
     * @param {string} deviceUuid 
     * @param {string} xExpiryTimestamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete(deviceUuid: string, xExpiryTimestamp: string, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).clearWizardSessionDevicesDeviceUuidConfigurationSessionClearDelete(deviceUuid, xExpiryTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Field Configuration
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public getFieldConfigurationDevicesDeviceUuidConfigurationGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).getFieldConfigurationDevicesDeviceUuidConfigurationGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Camera Config
     * @param {string} deviceUuid 
     * @param {EditCameraDetailsDTO} editCameraDetailsDTO 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editCameraDetailsDTO: EditCameraDetailsDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).setCameraConfigDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editCameraDetailsDTO, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Field Configuration
     * @param {string} deviceUuid 
     * @param {Payload} payload 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public setFieldConfigurationDevicesDeviceUuidConfigurationPost(deviceUuid: string, payload: Payload, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).setFieldConfigurationDevicesDeviceUuidConfigurationPost(deviceUuid, payload, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Wizard Session
     * @param {string} deviceUuid 
     * @param {boolean} [force] 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public startWizardSessionDevicesDeviceUuidConfigurationSessionPost(deviceUuid: string, force?: boolean, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).startWizardSessionDevicesDeviceUuidConfigurationSessionPost(deviceUuid, force, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop Wizard Session
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).stopWizardSessionDevicesDeviceUuidConfigurationSessionDelete(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deploy Automatictv
         * @param {string} deviceUuid 
         * @param {Upgrade} [upgrade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployAutomatictvDevicesDeviceUuidDeployPost: async (deviceUuid: string, upgrade?: Upgrade, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('deployAutomatictvDevicesDeviceUuidDeployPost', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/deploy/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (upgrade !== undefined) {
                localVarQueryParameter['upgrade'] = upgrade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deploy Remote Support
         * @param {string} deviceUuid 
         * @param {RemoteSupportDTO} remoteSupportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployRemoteSupportDevicesDeviceUuidRemoteSupportPost: async (deviceUuid: string, remoteSupportDTO: RemoteSupportDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('deployRemoteSupportDevicesDeviceUuidRemoteSupportPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'remoteSupportDTO' is not null or undefined
            assertParamExists('deployRemoteSupportDevicesDeviceUuidRemoteSupportPost', 'remoteSupportDTO', remoteSupportDTO)
            const localVarPath = `/devices/{device_uuid}/remote_support/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteSupportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Camera Detail
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraDetailDevicesDeviceUuidGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getCameraDetailDevicesDeviceUuidGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cameras List
         * @param {Name} [name] 
         * @param {DevicesSortOptions} [orderBy] 
         * @param {SortDirectionOptions} [orderDir] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCamerasListDevicesGet: async (name?: Name, orderBy?: DevicesSortOptions, orderDir?: SortDirectionOptions, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['order_dir'] = orderDir;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Deployment
         * @param {string} deviceUuid 
         * @param {string} deploymentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet: async (deviceUuid: string, deploymentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'deploymentUuid' is not null or undefined
            assertParamExists('getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet', 'deploymentUuid', deploymentUuid)
            const localVarPath = `/devices/{device_uuid}/deploy/{deployment_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"deployment_uuid"}}`, encodeURIComponent(String(deploymentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary License Device
         * @param {string} deviceUuid 
         * @param {LicenseDeviceDTO} licenseDeviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseDeviceDevicesDeviceUuidLicensePost: async (deviceUuid: string, licenseDeviceDTO: LicenseDeviceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('licenseDeviceDevicesDeviceUuidLicensePost', 'deviceUuid', deviceUuid)
            // verify required parameter 'licenseDeviceDTO' is not null or undefined
            assertParamExists('licenseDeviceDevicesDeviceUuidLicensePost', 'licenseDeviceDTO', licenseDeviceDTO)
            const localVarPath = `/devices/{device_uuid}/license/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseDeviceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deploy Automatictv
         * @param {string} deviceUuid 
         * @param {Upgrade} [upgrade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deployAutomatictvDevicesDeviceUuidDeployPost(deviceUuid: string, upgrade?: Upgrade, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeploymentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deployAutomatictvDevicesDeviceUuidDeployPost(deviceUuid, upgrade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deploy Remote Support
         * @param {string} deviceUuid 
         * @param {RemoteSupportDTO} remoteSupportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deployRemoteSupportDevicesDeviceUuidRemoteSupportPost(deviceUuid: string, remoteSupportDTO: RemoteSupportDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeploymentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deployRemoteSupportDevicesDeviceUuidRemoteSupportPost(deviceUuid, remoteSupportDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Camera Detail
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraDetailDevicesDeviceUuidGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CameraDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraDetailDevicesDeviceUuidGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cameras List
         * @param {Name} [name] 
         * @param {DevicesSortOptions} [orderBy] 
         * @param {SortDirectionOptions} [orderDir] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCamerasListDevicesGet(name?: Name, orderBy?: DevicesSortOptions, orderDir?: SortDirectionOptions, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCamerasListDevicesGet(name, orderBy, orderDir, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Deployment
         * @param {string} deviceUuid 
         * @param {string} deploymentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(deviceUuid: string, deploymentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeploymentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(deviceUuid, deploymentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary License Device
         * @param {string} deviceUuid 
         * @param {LicenseDeviceDTO} licenseDeviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licenseDeviceDevicesDeviceUuidLicensePost(deviceUuid: string, licenseDeviceDTO: LicenseDeviceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.licenseDeviceDevicesDeviceUuidLicensePost(deviceUuid, licenseDeviceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Deploy Automatictv
         * @param {string} deviceUuid 
         * @param {Upgrade} [upgrade] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployAutomatictvDevicesDeviceUuidDeployPost(deviceUuid: string, upgrade?: Upgrade, options?: any): AxiosPromise<DeviceDeploymentDTO> {
            return localVarFp.deployAutomatictvDevicesDeviceUuidDeployPost(deviceUuid, upgrade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deploy Remote Support
         * @param {string} deviceUuid 
         * @param {RemoteSupportDTO} remoteSupportDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deployRemoteSupportDevicesDeviceUuidRemoteSupportPost(deviceUuid: string, remoteSupportDTO: RemoteSupportDTO, options?: any): AxiosPromise<DeviceDeploymentDTO> {
            return localVarFp.deployRemoteSupportDevicesDeviceUuidRemoteSupportPost(deviceUuid, remoteSupportDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Camera Detail
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraDetailDevicesDeviceUuidGet(deviceUuid: string, options?: any): AxiosPromise<CameraDetailsDTO> {
            return localVarFp.getCameraDetailDevicesDeviceUuidGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cameras List
         * @param {Name} [name] 
         * @param {DevicesSortOptions} [orderBy] 
         * @param {SortDirectionOptions} [orderDir] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCamerasListDevicesGet(name?: Name, orderBy?: DevicesSortOptions, orderDir?: SortDirectionOptions, page?: number, size?: number, options?: any): AxiosPromise<PageDeviceDTO> {
            return localVarFp.getCamerasListDevicesGet(name, orderBy, orderDir, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Deployment
         * @param {string} deviceUuid 
         * @param {string} deploymentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(deviceUuid: string, deploymentUuid: string, options?: any): AxiosPromise<DeviceDeploymentDTO> {
            return localVarFp.getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(deviceUuid, deploymentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary License Device
         * @param {string} deviceUuid 
         * @param {LicenseDeviceDTO} licenseDeviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseDeviceDevicesDeviceUuidLicensePost(deviceUuid: string, licenseDeviceDTO: LicenseDeviceDTO, options?: any): AxiosPromise<DeviceDTO> {
            return localVarFp.licenseDeviceDevicesDeviceUuidLicensePost(deviceUuid, licenseDeviceDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     * 
     * @summary Deploy Automatictv
     * @param {string} deviceUuid 
     * @param {Upgrade} [upgrade] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deployAutomatictvDevicesDeviceUuidDeployPost(deviceUuid: string, upgrade?: Upgrade, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deployAutomatictvDevicesDeviceUuidDeployPost(deviceUuid, upgrade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deploy Remote Support
     * @param {string} deviceUuid 
     * @param {RemoteSupportDTO} remoteSupportDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deployRemoteSupportDevicesDeviceUuidRemoteSupportPost(deviceUuid: string, remoteSupportDTO: RemoteSupportDTO, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deployRemoteSupportDevicesDeviceUuidRemoteSupportPost(deviceUuid, remoteSupportDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Camera Detail
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getCameraDetailDevicesDeviceUuidGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getCameraDetailDevicesDeviceUuidGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cameras List
     * @param {Name} [name] 
     * @param {DevicesSortOptions} [orderBy] 
     * @param {SortDirectionOptions} [orderDir] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getCamerasListDevicesGet(name?: Name, orderBy?: DevicesSortOptions, orderDir?: SortDirectionOptions, page?: number, size?: number, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getCamerasListDevicesGet(name, orderBy, orderDir, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Deployment
     * @param {string} deviceUuid 
     * @param {string} deploymentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(deviceUuid: string, deploymentUuid: string, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeploymentDevicesDeviceUuidDeployDeploymentUuidGet(deviceUuid, deploymentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary License Device
     * @param {string} deviceUuid 
     * @param {LicenseDeviceDTO} licenseDeviceDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public licenseDeviceDevicesDeviceUuidLicensePost(deviceUuid: string, licenseDeviceDTO: LicenseDeviceDTO, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).licenseDeviceDevicesDeviceUuidLicensePost(deviceUuid, licenseDeviceDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevicesConfigurationApi - axios parameter creator
 * @export
 */
export const DevicesConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldConfigurationProDevicesDeviceUuidConfigurationGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFieldConfigurationProDevicesDeviceUuidConfigurationGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/configuration/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost: async (deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'editDeviceDetailsDTO' is not null or undefined
            assertParamExists('setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost', 'editDeviceDetailsDTO', editDeviceDetailsDTO)
            const localVarPath = `/pro/devices/{device_uuid}/configuration/camera_config/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editDeviceDetailsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set all the options available with all the options for field details form (1st step)
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {SetFieldConfig} setFieldConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldConfigurationProDevicesDeviceUuidConfigurationPost: async (deviceUuid: string, setFieldConfig: SetFieldConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('setFieldConfigurationProDevicesDeviceUuidConfigurationPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'setFieldConfig' is not null or undefined
            assertParamExists('setFieldConfigurationProDevicesDeviceUuidConfigurationPost', 'setFieldConfig', setFieldConfig)
            const localVarPath = `/pro/devices/{device_uuid}/configuration/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setFieldConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesConfigurationApi - functional programming interface
 * @export
 */
export const DevicesConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceProConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editDeviceDetailsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set all the options available with all the options for field details form (1st step)
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {SetFieldConfig} setFieldConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid: string, setFieldConfig: SetFieldConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceProConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid, setFieldConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicesConfigurationApi - factory interface
 * @export
 */
export const DevicesConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesConfigurationApiFp(configuration)
    return {
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid: string, options?: any): AxiosPromise<DeviceProConfigurationDTO> {
            return localVarFp.getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options?: any): AxiosPromise<DefaultResponseDTO> {
            return localVarFp.setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editDeviceDetailsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Set all the options available with all the options for field details form (1st step)
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {SetFieldConfig} setFieldConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid: string, setFieldConfig: SetFieldConfig, options?: any): AxiosPromise<DeviceProConfigurationDTO> {
            return localVarFp.setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid, setFieldConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesConfigurationApi - object-oriented interface
 * @export
 * @class DevicesConfigurationApi
 * @extends {BaseAPI}
 */
export class DevicesConfigurationApi extends BaseAPI {
    /**
     * Get all the options available with all the options for field details form (1st step)
     * @summary Get Field Configuration
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesConfigurationApi
     */
    public getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return DevicesConfigurationApiFp(this.configuration).getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Camera Config
     * @param {string} deviceUuid 
     * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesConfigurationApi
     */
    public setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options?: AxiosRequestConfig) {
        return DevicesConfigurationApiFp(this.configuration).setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editDeviceDetailsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set all the options available with all the options for field details form (1st step)
     * @summary Set Field Configuration
     * @param {string} deviceUuid 
     * @param {SetFieldConfig} setFieldConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesConfigurationApi
     */
    public setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid: string, setFieldConfig: SetFieldConfig, options?: AxiosRequestConfig) {
        return DevicesConfigurationApiFp(this.configuration).setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid, setFieldConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OptionsApi - axios parameter creator
 * @export
 */
export const OptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all the information needed to print the left side of the calibration page. An image with all the lines, the dimension of the field and a list of points with the ID
         * @summary Get Calibration Options
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet: async (deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/options/calibration/{sport_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the options available with their respective choices for the camera settings form (2nd step)
         * @summary Get Camera Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraOptionsDevicesDeviceUuidOptionsCamerasGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getCameraOptionsDevicesDeviceUuidOptionsCamerasGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/options/cameras/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Camera Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraTypesOptionsCameraTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/options/camera_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get protocols and qualities available for the destinations form (5th step)
         * @summary Get Destination Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/options/destinations/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptionsDevicesDeviceUuidOptionsDetailsGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFieldOptionsDevicesDeviceUuidOptionsDetailsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/options/details/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/options/details/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Graphic Plugins
         * @param {string} deviceUuid 
         * @param {TypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet: async (deviceUuid: string, type?: TypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/options/smart_scoreboard/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Graphic Template
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet: async (deviceUuid: string, sportUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/options/smart_scoreboard/template/{sport_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the sports available (configured) with their respective choices for the preset settings form (1st step)
         * @summary Get Preset Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/options/preset_settings/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the modes and styles available, for specific sport, with their respective choices for the preset settings form (1st step)
         * @summary Get Preset Sport Options
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet: async (deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'sportUuid' is not null or undefined
            assertParamExists('getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet', 'sportUuid', sportUuid)
            const localVarPath = `/devices/{device_uuid}/options/preset_settings/{sport_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"sport_uuid"}}`, encodeURIComponent(String(sportUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of sport groups with all the sports and sportfields
         * @summary Get Sports
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportsDevicesDeviceUuidOptionsSportsGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getSportsDevicesDeviceUuidOptionsSportsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/options/sports/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionsApi - functional programming interface
 * @export
 */
export const OptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all the information needed to print the left side of the calibration page. An image with all the lines, the dimension of the field and a list of points with the ID
         * @summary Get Calibration Options
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalibrationOptionsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet(deviceUuid, sportUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the options available with their respective choices for the camera settings form (2nd step)
         * @summary Get Camera Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraOptionsDevicesDeviceUuidOptionsCamerasGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraOptionsDevicesDeviceUuidOptionsCamerasGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Camera Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraTypesOptionsCameraTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraTypesOptionsCameraTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get protocols and qualities available for the destinations form (5th step)
         * @summary Get Destination Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Graphic Plugins
         * @param {string} deviceUuid 
         * @param {TypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(deviceUuid: string, type?: TypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(deviceUuid, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Graphic Template
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet(deviceUuid: string, sportUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmartScoreboardTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet(deviceUuid, sportUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the sports available (configured) with their respective choices for the preset settings form (1st step)
         * @summary Get Preset Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the modes and styles available, for specific sport, with their respective choices for the preset settings form (1st step)
         * @summary Get Preset Sport Options
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet(deviceUuid, sportUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of sport groups with all the sports and sportfields
         * @summary Get Sports
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSportsDevicesDeviceUuidOptionsSportsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SportGroupDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSportsDevicesDeviceUuidOptionsSportsGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OptionsApi - factory interface
 * @export
 */
export const OptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionsApiFp(configuration)
    return {
        /**
         * Get all the information needed to print the left side of the calibration page. An image with all the lines, the dimension of the field and a list of points with the ID
         * @summary Get Calibration Options
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<CalibrationOptionsDTO> {
            return localVarFp.getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the options available with their respective choices for the camera settings form (2nd step)
         * @summary Get Camera Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraOptionsDevicesDeviceUuidOptionsCamerasGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getCameraOptionsDevicesDeviceUuidOptionsCamerasGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Camera Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraTypesOptionsCameraTypesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCameraTypesOptionsCameraTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get protocols and qualities available for the destinations form (5th step)
         * @summary Get Destination Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Graphic Plugins
         * @param {string} deviceUuid 
         * @param {TypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(deviceUuid: string, type?: TypeEnum, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(deviceUuid, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Graphic Template
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet(deviceUuid: string, sportUuid: string, options?: any): AxiosPromise<SmartScoreboardTemplate> {
            return localVarFp.getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet(deviceUuid, sportUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the sports available (configured) with their respective choices for the preset settings form (1st step)
         * @summary Get Preset Options
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the modes and styles available, for specific sport, with their respective choices for the preset settings form (1st step)
         * @summary Get Preset Sport Options
         * @param {string} deviceUuid 
         * @param {string} sportUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of sport groups with all the sports and sportfields
         * @summary Get Sports
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSportsDevicesDeviceUuidOptionsSportsGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<Array<SportGroupDTO>> {
            return localVarFp.getSportsDevicesDeviceUuidOptionsSportsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OptionsApi - object-oriented interface
 * @export
 * @class OptionsApi
 * @extends {BaseAPI}
 */
export class OptionsApi extends BaseAPI {
    /**
     * Get all the information needed to print the left side of the calibration page. An image with all the lines, the dimension of the field and a list of points with the ID
     * @summary Get Calibration Options
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getCalibrationOptionsDevicesDeviceUuidOptionsCalibrationSportUuidGet(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the options available with their respective choices for the camera settings form (2nd step)
     * @summary Get Camera Options
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getCameraOptionsDevicesDeviceUuidOptionsCamerasGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getCameraOptionsDevicesDeviceUuidOptionsCamerasGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Camera Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getCameraTypesOptionsCameraTypesGet(options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getCameraTypesOptionsCameraTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get protocols and qualities available for the destinations form (5th step)
     * @summary Get Destination Options
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getDestinationOptionsDevicesDeviceUuidOptionsDestinationsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the options available with all the options for field details form (1st step)
     * @summary Get Field Options
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getFieldOptionsDevicesDeviceUuidOptionsDetailsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Field Options
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Graphic Plugins
     * @param {string} deviceUuid 
     * @param {TypeEnum} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(deviceUuid: string, type?: TypeEnum, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getGraphicPluginsDevicesDeviceUuidOptionsSmartScoreboardGet(deviceUuid, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Graphic Template
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet(deviceUuid: string, sportUuid: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getGraphicTemplateDevicesDeviceUuidOptionsSmartScoreboardTemplateSportUuidGet(deviceUuid, sportUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the sports available (configured) with their respective choices for the preset settings form (1st step)
     * @summary Get Preset Options
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getPresetOptionsDevicesDeviceUuidOptionsPresetSettingsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the modes and styles available, for specific sport, with their respective choices for the preset settings form (1st step)
     * @summary Get Preset Sport Options
     * @param {string} deviceUuid 
     * @param {string} sportUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet(deviceUuid: string, sportUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getPresetSportOptionsDevicesDeviceUuidOptionsPresetSettingsSportUuidGet(deviceUuid, sportUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of sport groups with all the sports and sportfields
     * @summary Get Sports
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public getSportsDevicesDeviceUuidOptionsSportsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return OptionsApiFp(this.configuration).getSportsDevicesDeviceUuidOptionsSportsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsOrganizationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pair Device
         * @param {string} organizationUuid 
         * @param {DeviceRegisterDTO} deviceRegisterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairDeviceOrganizationsOrganizationUuidPairDevicePost: async (organizationUuid: string, deviceRegisterDTO: DeviceRegisterDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('pairDeviceOrganizationsOrganizationUuidPairDevicePost', 'organizationUuid', organizationUuid)
            // verify required parameter 'deviceRegisterDTO' is not null or undefined
            assertParamExists('pairDeviceOrganizationsOrganizationUuidPairDevicePost', 'deviceRegisterDTO', deviceRegisterDTO)
            const localVarPath = `/organizations/{organization_uuid}/pair_device/`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceRegisterDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsOrganizationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsOrganizationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pair Device
         * @param {string} organizationUuid 
         * @param {DeviceRegisterDTO} deviceRegisterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pairDeviceOrganizationsOrganizationUuidPairDevicePost(organizationUuid: string, deviceRegisterDTO: DeviceRegisterDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceRegisterResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pairDeviceOrganizationsOrganizationUuidPairDevicePost(organizationUuid, deviceRegisterDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsOrganizationsGet(options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getOrganizationsOrganizationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pair Device
         * @param {string} organizationUuid 
         * @param {DeviceRegisterDTO} deviceRegisterDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairDeviceOrganizationsOrganizationUuidPairDevicePost(organizationUuid: string, deviceRegisterDTO: DeviceRegisterDTO, options?: any): AxiosPromise<DeviceRegisterResponseDTO> {
            return localVarFp.pairDeviceOrganizationsOrganizationUuidPairDevicePost(organizationUuid, deviceRegisterDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationsOrganizationsGet(options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationsOrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pair Device
     * @param {string} organizationUuid 
     * @param {DeviceRegisterDTO} deviceRegisterDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public pairDeviceOrganizationsOrganizationUuidPairDevicePost(organizationUuid: string, deviceRegisterDTO: DeviceRegisterDTO, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).pairDeviceOrganizationsOrganizationUuidPairDevicePost(organizationUuid, deviceRegisterDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PresetsApi - axios parameter creator
 * @export
 */
export const PresetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check Preset Name Availability
         * @param {string} deviceUuid 
         * @param {string} name 
         * @param {PresetUuid} [presetUuid] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet: async (deviceUuid: string, name: string, presetUuid?: PresetUuid, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet', 'name', name)
            const localVarPath = `/devices/{device_uuid}/presets/check_preset_name_availability`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (presetUuid !== undefined) {
                localVarQueryParameter['preset_uuid'] = presetUuid;
            }

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to delete a preset.
         * @summary Delete Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePresetDevicesDeviceUuidPresetsPresetUuidDelete: async (deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('deletePresetDevicesDeviceUuidPresetsPresetUuidDelete', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('deletePresetDevicesDeviceUuidPresetsPresetUuidDelete', 'presetUuid', presetUuid)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to create a copy of a preset automatically. It can accept a name in the payload, if no payload provided we are going to generate one from the original name.
         * @summary Duplicate Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {Payload2} payload2 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost: async (deviceUuid: string, presetUuid: string, payload2: Payload2, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost', 'presetUuid', presetUuid)
            // verify required parameter 'payload2' is not null or undefined
            assertParamExists('duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost', 'payload2', payload2)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/duplicate/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit preset.
         * @summary Edit Preset
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPresetProDevicesDeviceUuidPresetsPresetUuidPatch: async (presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('editPresetProDevicesDeviceUuidPresetsPresetUuidPatch', 'presetUuid', presetUuid)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('editPresetProDevicesDeviceUuidPresetsPresetUuidPatch', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetProOverlaysDTO' is not null or undefined
            assertParamExists('editPresetProDevicesDeviceUuidPresetsPresetUuidPatch', 'presetProOverlaysDTO', presetProOverlaysDTO)
            const localVarPath = `/pro/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(presetProOverlaysDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to favorite a preset or remove it from favorited presets
         * @summary Favorite Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {FavoritePreset} favoritePreset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch: async (deviceUuid: string, presetUuid: string, favoritePreset: FavoritePreset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch', 'presetUuid', presetUuid)
            // verify required parameter 'favoritePreset' is not null or undefined
            assertParamExists('favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch', 'favoritePreset', favoritePreset)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/favorite/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoritePreset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an image of the desired feed
         * @summary Get Feed Image
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet: async (deviceUuid: string, presetUuid: string, feedName: FeedNameEnum, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'presetUuid', presetUuid)
            // verify required parameter 'feedName' is not null or undefined
            assertParamExists('getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'feedName', feedName)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/feed/{feed_name}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"feed_name"}}`, encodeURIComponent(String(feedName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feed Image
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet: async (presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'presetUuid', presetUuid)
            // verify required parameter 'feedName' is not null or undefined
            assertParamExists('getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'feedName', feedName)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/presets/{preset_uuid}/feed/{feed_name}/`
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"feed_name"}}`, encodeURIComponent(String(feedName)))
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the whole graphics configuration filtered by the preset.
         * @summary Get Graphics By Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet: async (deviceUuid: string, presetUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet', 'presetUuid', presetUuid)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/graphics_configuration`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an image from the resources
         * @summary Get Image Resource
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} imageUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet: async (deviceUuid: string, presetUuid: string, imageUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet', 'presetUuid', presetUuid)
            // verify required parameter 'imageUuid' is not null or undefined
            assertParamExists('getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet', 'imageUuid', imageUuid)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/image/{image_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"image_uuid"}}`, encodeURIComponent(String(imageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get presets for device.
         * @summary Get Preset Details
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet: async (presetUuid: string, deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet', 'presetUuid', presetUuid)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetDevicesDeviceUuidPresetsPresetUuidGet: async (deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetDevicesDeviceUuidPresetsPresetUuidGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getPresetDevicesDeviceUuidPresetsPresetUuidGet', 'presetUuid', presetUuid)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsDevicesDeviceUuidPresetsGet: async (deviceUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetsDevicesDeviceUuidPresetsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/devices/{device_uuid}/presets/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get presets for device.
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsProDevicesDeviceUuidPresetsGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetsProDevicesDeviceUuidPresetsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/presets/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to generate a preset skaffold with an assigned uuid that will be used for the following tasks:  - edit camera settings  - get feed image  - upload images  If we receive a request to initialize a new endpoint and there is a unsaved skaffold in DB, we are going to delete the existing preset and all the images and calibrations related to that preset and finally recreate a new one.  Also, this endpoint should include a payload with the sport and production style, for this reason we should call this endpoint after the step 1 of the \"new production preset\" workflow.  Optionally, we can pass all the rest of information about the preset if we want to set the parameters to specific ones, for example, if we are editing an existing preset, we should pass the uuid at least, if we are comming back from future steps, we should pass all the fields already setted.
         * @summary Init Preset
         * @param {string} deviceUuid 
         * @param {Payload1} payload1 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initPresetDevicesDeviceUuidPresetsPost: async (deviceUuid: string, payload1: Payload1, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('initPresetDevicesDeviceUuidPresetsPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'payload1' is not null or undefined
            assertParamExists('initPresetDevicesDeviceUuidPresetsPost', 'payload1', payload1)
            const localVarPath = `/devices/{device_uuid}/presets/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payload1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to save a preset skaffold and make it available to use or to update an existing preset
         * @summary Save Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {EditDevicePresetDTO} editDevicePresetDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePresetDevicesDeviceUuidPresetsPresetUuidPut: async (deviceUuid: string, presetUuid: string, editDevicePresetDTO: EditDevicePresetDTO, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('savePresetDevicesDeviceUuidPresetsPresetUuidPut', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('savePresetDevicesDeviceUuidPresetsPresetUuidPut', 'presetUuid', presetUuid)
            // verify required parameter 'editDevicePresetDTO' is not null or undefined
            assertParamExists('savePresetDevicesDeviceUuidPresetsPresetUuidPut', 'editDevicePresetDTO', editDevicePresetDTO)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editDevicePresetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unload the given preset.
         * @summary Unload Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost: async (deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost', 'presetUuid', presetUuid)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/unload`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update camera settings
         * @summary Update Camera Setting
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {ChangeSetting} changeSetting 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch: async (deviceUuid: string, presetUuid: string, changeSetting: ChangeSetting, xWizardSessionToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch', 'presetUuid', presetUuid)
            // verify required parameter 'changeSetting' is not null or undefined
            assertParamExists('updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch', 'changeSetting', changeSetting)
            const localVarPath = `/devices/{device_uuid}/presets/{preset_uuid}/camera_setting/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)))
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)

            if (xWizardSessionToken != null) {
                localVarHeaderParameter['x-wizard-session-token'] = String(xWizardSessionToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PresetsApi - functional programming interface
 * @export
 */
export const PresetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PresetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check Preset Name Availability
         * @param {string} deviceUuid 
         * @param {string} name 
         * @param {PresetUuid} [presetUuid] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet(deviceUuid: string, name: string, presetUuid?: PresetUuid, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet(deviceUuid, name, presetUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to delete a preset.
         * @summary Delete Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePresetDevicesDeviceUuidPresetsPresetUuidDelete(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePresetDevicesDeviceUuidPresetsPresetUuidDelete(deviceUuid, presetUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to create a copy of a preset automatically. It can accept a name in the payload, if no payload provided we are going to generate one from the original name.
         * @summary Duplicate Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {Payload2} payload2 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost(deviceUuid: string, presetUuid: string, payload2: Payload2, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicePresetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost(deviceUuid, presetUuid, payload2, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit preset.
         * @summary Edit Preset
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseDevicePresetProDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid, deviceUuid, presetProOverlaysDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to favorite a preset or remove it from favorited presets
         * @summary Favorite Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {FavoritePreset} favoritePreset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch(deviceUuid: string, presetUuid: string, favoritePreset: FavoritePreset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch(deviceUuid, presetUuid, favoritePreset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an image of the desired feed
         * @summary Get Feed Image
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(deviceUuid: string, presetUuid: string, feedName: FeedNameEnum, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(deviceUuid, presetUuid, feedName, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Feed Image
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid, feedName, deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the whole graphics configuration filtered by the preset.
         * @summary Get Graphics By Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet(deviceUuid: string, presetUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceOCRFilesOptions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet(deviceUuid, presetUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an image from the resources
         * @summary Get Image Resource
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} imageUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet(deviceUuid: string, presetUuid: string, imageUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet(deviceUuid, presetUuid, imageUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get presets for device.
         * @summary Get Preset Details
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid: string, deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseDevicePresetProDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid, deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetDevicesDeviceUuidPresetsPresetUuidGet(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicePresetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetDevicesDeviceUuidPresetsPresetUuidGet(deviceUuid, presetUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetsDevicesDeviceUuidPresetsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevicePresetDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetsDevicesDeviceUuidPresetsGet(deviceUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get presets for device.
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseDevicePresetProDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to generate a preset skaffold with an assigned uuid that will be used for the following tasks:  - edit camera settings  - get feed image  - upload images  If we receive a request to initialize a new endpoint and there is a unsaved skaffold in DB, we are going to delete the existing preset and all the images and calibrations related to that preset and finally recreate a new one.  Also, this endpoint should include a payload with the sport and production style, for this reason we should call this endpoint after the step 1 of the \"new production preset\" workflow.  Optionally, we can pass all the rest of information about the preset if we want to set the parameters to specific ones, for example, if we are editing an existing preset, we should pass the uuid at least, if we are comming back from future steps, we should pass all the fields already setted.
         * @summary Init Preset
         * @param {string} deviceUuid 
         * @param {Payload1} payload1 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initPresetDevicesDeviceUuidPresetsPost(deviceUuid: string, payload1: Payload1, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicePresetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initPresetDevicesDeviceUuidPresetsPost(deviceUuid, payload1, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to save a preset skaffold and make it available to use or to update an existing preset
         * @summary Save Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {EditDevicePresetDTO} editDevicePresetDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePresetDevicesDeviceUuidPresetsPresetUuidPut(deviceUuid: string, presetUuid: string, editDevicePresetDTO: EditDevicePresetDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicePresetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePresetDevicesDeviceUuidPresetsPresetUuidPut(deviceUuid, presetUuid, editDevicePresetDTO, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unload the given preset.
         * @summary Unload Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost(deviceUuid, presetUuid, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update camera settings
         * @summary Update Camera Setting
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {ChangeSetting} changeSetting 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch(deviceUuid: string, presetUuid: string, changeSetting: ChangeSetting, xWizardSessionToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch(deviceUuid, presetUuid, changeSetting, xWizardSessionToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PresetsApi - factory interface
 * @export
 */
export const PresetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PresetsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check Preset Name Availability
         * @param {string} deviceUuid 
         * @param {string} name 
         * @param {PresetUuid} [presetUuid] 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet(deviceUuid: string, name: string, presetUuid?: PresetUuid, xWizardSessionToken?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet(deviceUuid, name, presetUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to delete a preset.
         * @summary Delete Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePresetDevicesDeviceUuidPresetsPresetUuidDelete(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePresetDevicesDeviceUuidPresetsPresetUuidDelete(deviceUuid, presetUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to create a copy of a preset automatically. It can accept a name in the payload, if no payload provided we are going to generate one from the original name.
         * @summary Duplicate Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {Payload2} payload2 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost(deviceUuid: string, presetUuid: string, payload2: Payload2, xWizardSessionToken?: string, options?: any): AxiosPromise<DevicePresetDTO> {
            return localVarFp.duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost(deviceUuid, presetUuid, payload2, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit preset.
         * @summary Edit Preset
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options?: any): AxiosPromise<BaseDevicePresetProDTO> {
            return localVarFp.editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid, deviceUuid, presetProOverlaysDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to favorite a preset or remove it from favorited presets
         * @summary Favorite Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {FavoritePreset} favoritePreset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch(deviceUuid: string, presetUuid: string, favoritePreset: FavoritePreset, options?: any): AxiosPromise<DefaultResponseDTO> {
            return localVarFp.favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch(deviceUuid, presetUuid, favoritePreset, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an image of the desired feed
         * @summary Get Feed Image
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(deviceUuid: string, presetUuid: string, feedName: FeedNameEnum, xWizardSessionToken?: string, options?: any): AxiosPromise<Image> {
            return localVarFp.getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(deviceUuid, presetUuid, feedName, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feed Image
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options?: any): AxiosPromise<Image> {
            return localVarFp.getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid, feedName, deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the whole graphics configuration filtered by the preset.
         * @summary Get Graphics By Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet(deviceUuid: string, presetUuid: string, options?: any): AxiosPromise<Array<DeviceOCRFilesOptions>> {
            return localVarFp.getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet(deviceUuid, presetUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an image from the resources
         * @summary Get Image Resource
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} imageUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet(deviceUuid: string, presetUuid: string, imageUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<Image> {
            return localVarFp.getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet(deviceUuid, presetUuid, imageUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get presets for device.
         * @summary Get Preset Details
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid: string, deviceUuid: string, options?: any): AxiosPromise<BaseDevicePresetProDTO> {
            return localVarFp.getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid, deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetDevicesDeviceUuidPresetsPresetUuidGet(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<DevicePresetDTO> {
            return localVarFp.getPresetDevicesDeviceUuidPresetsPresetUuidGet(deviceUuid, presetUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsDevicesDeviceUuidPresetsGet(deviceUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<Array<DevicePresetDTO>> {
            return localVarFp.getPresetsDevicesDeviceUuidPresetsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get presets for device.
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid: string, options?: any): AxiosPromise<Array<BaseDevicePresetProDTO>> {
            return localVarFp.getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to generate a preset skaffold with an assigned uuid that will be used for the following tasks:  - edit camera settings  - get feed image  - upload images  If we receive a request to initialize a new endpoint and there is a unsaved skaffold in DB, we are going to delete the existing preset and all the images and calibrations related to that preset and finally recreate a new one.  Also, this endpoint should include a payload with the sport and production style, for this reason we should call this endpoint after the step 1 of the \"new production preset\" workflow.  Optionally, we can pass all the rest of information about the preset if we want to set the parameters to specific ones, for example, if we are editing an existing preset, we should pass the uuid at least, if we are comming back from future steps, we should pass all the fields already setted.
         * @summary Init Preset
         * @param {string} deviceUuid 
         * @param {Payload1} payload1 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initPresetDevicesDeviceUuidPresetsPost(deviceUuid: string, payload1: Payload1, xWizardSessionToken?: string, options?: any): AxiosPromise<DevicePresetDTO> {
            return localVarFp.initPresetDevicesDeviceUuidPresetsPost(deviceUuid, payload1, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to save a preset skaffold and make it available to use or to update an existing preset
         * @summary Save Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {EditDevicePresetDTO} editDevicePresetDTO 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePresetDevicesDeviceUuidPresetsPresetUuidPut(deviceUuid: string, presetUuid: string, editDevicePresetDTO: EditDevicePresetDTO, xWizardSessionToken?: string, options?: any): AxiosPromise<DevicePresetDTO> {
            return localVarFp.savePresetDevicesDeviceUuidPresetsPresetUuidPut(deviceUuid, presetUuid, editDevicePresetDTO, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Unload the given preset.
         * @summary Unload Preset
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost(deviceUuid, presetUuid, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Update camera settings
         * @summary Update Camera Setting
         * @param {string} deviceUuid 
         * @param {string} presetUuid 
         * @param {ChangeSetting} changeSetting 
         * @param {string} [xWizardSessionToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch(deviceUuid: string, presetUuid: string, changeSetting: ChangeSetting, xWizardSessionToken?: string, options?: any): AxiosPromise<StatusResponse> {
            return localVarFp.updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch(deviceUuid, presetUuid, changeSetting, xWizardSessionToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PresetsApi - object-oriented interface
 * @export
 * @class PresetsApi
 * @extends {BaseAPI}
 */
export class PresetsApi extends BaseAPI {
    /**
     * 
     * @summary Check Preset Name Availability
     * @param {string} deviceUuid 
     * @param {string} name 
     * @param {PresetUuid} [presetUuid] 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet(deviceUuid: string, name: string, presetUuid?: PresetUuid, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).checkPresetNameAvailabilityDevicesDeviceUuidPresetsCheckPresetNameAvailabilityGet(deviceUuid, name, presetUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to delete a preset.
     * @summary Delete Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public deletePresetDevicesDeviceUuidPresetsPresetUuidDelete(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).deletePresetDevicesDeviceUuidPresetsPresetUuidDelete(deviceUuid, presetUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to create a copy of a preset automatically. It can accept a name in the payload, if no payload provided we are going to generate one from the original name.
     * @summary Duplicate Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {Payload2} payload2 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost(deviceUuid: string, presetUuid: string, payload2: Payload2, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).duplicatePresetDevicesDeviceUuidPresetsPresetUuidDuplicatePost(deviceUuid, presetUuid, payload2, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit preset.
     * @summary Edit Preset
     * @param {string} presetUuid 
     * @param {string} deviceUuid 
     * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid, deviceUuid, presetProOverlaysDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to favorite a preset or remove it from favorited presets
     * @summary Favorite Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {FavoritePreset} favoritePreset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch(deviceUuid: string, presetUuid: string, favoritePreset: FavoritePreset, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).favoritePresetDevicesDeviceUuidPresetsPresetUuidFavoritePatch(deviceUuid, presetUuid, favoritePreset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an image of the desired feed
     * @summary Get Feed Image
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {FeedNameEnum} feedName 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(deviceUuid: string, presetUuid: string, feedName: FeedNameEnum, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getFeedImageDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(deviceUuid, presetUuid, feedName, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feed Image
     * @param {string} presetUuid 
     * @param {FeedNameEnum} feedName 
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid, feedName, deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the whole graphics configuration filtered by the preset.
     * @summary Get Graphics By Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet(deviceUuid: string, presetUuid: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getGraphicsByPresetDevicesDeviceUuidPresetsPresetUuidGraphicsConfigurationGet(deviceUuid, presetUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an image from the resources
     * @summary Get Image Resource
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {string} imageUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet(deviceUuid: string, presetUuid: string, imageUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getImageResourceDevicesDeviceUuidPresetsPresetUuidImageImageUuidGet(deviceUuid, presetUuid, imageUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get presets for device.
     * @summary Get Preset Details
     * @param {string} presetUuid 
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid: string, deviceUuid: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid, deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getPresetDevicesDeviceUuidPresetsPresetUuidGet(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getPresetDevicesDeviceUuidPresetsPresetUuidGet(deviceUuid, presetUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Presets
     * @param {string} deviceUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getPresetsDevicesDeviceUuidPresetsGet(deviceUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getPresetsDevicesDeviceUuidPresetsGet(deviceUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get presets for device.
     * @summary Get Presets
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to generate a preset skaffold with an assigned uuid that will be used for the following tasks:  - edit camera settings  - get feed image  - upload images  If we receive a request to initialize a new endpoint and there is a unsaved skaffold in DB, we are going to delete the existing preset and all the images and calibrations related to that preset and finally recreate a new one.  Also, this endpoint should include a payload with the sport and production style, for this reason we should call this endpoint after the step 1 of the \"new production preset\" workflow.  Optionally, we can pass all the rest of information about the preset if we want to set the parameters to specific ones, for example, if we are editing an existing preset, we should pass the uuid at least, if we are comming back from future steps, we should pass all the fields already setted.
     * @summary Init Preset
     * @param {string} deviceUuid 
     * @param {Payload1} payload1 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public initPresetDevicesDeviceUuidPresetsPost(deviceUuid: string, payload1: Payload1, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).initPresetDevicesDeviceUuidPresetsPost(deviceUuid, payload1, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to save a preset skaffold and make it available to use or to update an existing preset
     * @summary Save Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {EditDevicePresetDTO} editDevicePresetDTO 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public savePresetDevicesDeviceUuidPresetsPresetUuidPut(deviceUuid: string, presetUuid: string, editDevicePresetDTO: EditDevicePresetDTO, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).savePresetDevicesDeviceUuidPresetsPresetUuidPut(deviceUuid, presetUuid, editDevicePresetDTO, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unload the given preset.
     * @summary Unload Preset
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost(deviceUuid: string, presetUuid: string, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).unloadPresetDevicesDeviceUuidPresetsPresetUuidUnloadPost(deviceUuid, presetUuid, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update camera settings
     * @summary Update Camera Setting
     * @param {string} deviceUuid 
     * @param {string} presetUuid 
     * @param {ChangeSetting} changeSetting 
     * @param {string} [xWizardSessionToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetsApi
     */
    public updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch(deviceUuid: string, presetUuid: string, changeSetting: ChangeSetting, xWizardSessionToken?: string, options?: AxiosRequestConfig) {
        return PresetsApiFp(this.configuration).updateCameraSettingDevicesDeviceUuidPresetsPresetUuidCameraSettingPatch(deviceUuid, presetUuid, changeSetting, xWizardSessionToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProBroadcastApi - axios parameter creator
 * @export
 */
export const ProBroadcastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Edit preset.
         * @summary Edit Preset
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPresetProDevicesDeviceUuidPresetsPresetUuidPatch: async (presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('editPresetProDevicesDeviceUuidPresetsPresetUuidPatch', 'presetUuid', presetUuid)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('editPresetProDevicesDeviceUuidPresetsPresetUuidPatch', 'deviceUuid', deviceUuid)
            // verify required parameter 'presetProOverlaysDTO' is not null or undefined
            assertParamExists('editPresetProDevicesDeviceUuidPresetsPresetUuidPatch', 'presetProOverlaysDTO', presetProOverlaysDTO)
            const localVarPath = `/pro/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(presetProOverlaysDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feed Image
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet: async (presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'presetUuid', presetUuid)
            // verify required parameter 'feedName' is not null or undefined
            assertParamExists('getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'feedName', feedName)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/presets/{preset_uuid}/feed/{feed_name}/`
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"feed_name"}}`, encodeURIComponent(String(feedName)))
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldConfigurationProDevicesDeviceUuidConfigurationGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFieldConfigurationProDevicesDeviceUuidConfigurationGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/configuration/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/options/details/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get presets for device.
         * @summary Get Preset Details
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet: async (presetUuid: string, deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'presetUuid' is not null or undefined
            assertParamExists('getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet', 'presetUuid', presetUuid)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/presets/{preset_uuid}/`
                .replace(`{${"preset_uuid"}}`, encodeURIComponent(String(presetUuid)))
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get presets for device.
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsProDevicesDeviceUuidPresetsGet: async (deviceUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('getPresetsProDevicesDeviceUuidPresetsGet', 'deviceUuid', deviceUuid)
            const localVarPath = `/pro/devices/{device_uuid}/presets/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost: async (deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'editDeviceDetailsDTO' is not null or undefined
            assertParamExists('setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost', 'editDeviceDetailsDTO', editDeviceDetailsDTO)
            const localVarPath = `/pro/devices/{device_uuid}/configuration/camera_config/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editDeviceDetailsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set all the options available with all the options for field details form (1st step)
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {SetFieldConfig} setFieldConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldConfigurationProDevicesDeviceUuidConfigurationPost: async (deviceUuid: string, setFieldConfig: SetFieldConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('setFieldConfigurationProDevicesDeviceUuidConfigurationPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'setFieldConfig' is not null or undefined
            assertParamExists('setFieldConfigurationProDevicesDeviceUuidConfigurationPost', 'setFieldConfig', setFieldConfig)
            const localVarPath = `/pro/devices/{device_uuid}/configuration/`
                .replace(`{${"device_uuid"}}`, encodeURIComponent(String(deviceUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setFieldConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProBroadcastApi - functional programming interface
 * @export
 */
export const ProBroadcastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProBroadcastApiAxiosParamCreator(configuration)
    return {
        /**
         * Edit preset.
         * @summary Edit Preset
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseDevicePresetProDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid, deviceUuid, presetProOverlaysDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Feed Image
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid, feedName, deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceProConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: OptionDetails; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get presets for device.
         * @summary Get Preset Details
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid: string, deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseDevicePresetProDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid, deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get presets for device.
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseDevicePresetProDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editDeviceDetailsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set all the options available with all the options for field details form (1st step)
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {SetFieldConfig} setFieldConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid: string, setFieldConfig: SetFieldConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceProConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid, setFieldConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProBroadcastApi - factory interface
 * @export
 */
export const ProBroadcastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProBroadcastApiFp(configuration)
    return {
        /**
         * Edit preset.
         * @summary Edit Preset
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options?: any): AxiosPromise<BaseDevicePresetProDTO> {
            return localVarFp.editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid, deviceUuid, presetProOverlaysDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feed Image
         * @param {string} presetUuid 
         * @param {FeedNameEnum} feedName 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options?: any): AxiosPromise<Image> {
            return localVarFp.getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid, feedName, deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the options available with all the options for field details form (1st step)
         * @summary Get Field Configuration
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid: string, options?: any): AxiosPromise<DeviceProConfigurationDTO> {
            return localVarFp.getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Field Options
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, options?: any): AxiosPromise<{ [key: string]: OptionDetails; }> {
            return localVarFp.getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get presets for device.
         * @summary Get Preset Details
         * @param {string} presetUuid 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid: string, deviceUuid: string, options?: any): AxiosPromise<BaseDevicePresetProDTO> {
            return localVarFp.getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid, deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get presets for device.
         * @summary Get Presets
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid: string, options?: any): AxiosPromise<Array<BaseDevicePresetProDTO>> {
            return localVarFp.getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Camera Config
         * @param {string} deviceUuid 
         * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options?: any): AxiosPromise<DefaultResponseDTO> {
            return localVarFp.setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editDeviceDetailsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Set all the options available with all the options for field details form (1st step)
         * @summary Set Field Configuration
         * @param {string} deviceUuid 
         * @param {SetFieldConfig} setFieldConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid: string, setFieldConfig: SetFieldConfig, options?: any): AxiosPromise<DeviceProConfigurationDTO> {
            return localVarFp.setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid, setFieldConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProBroadcastApi - object-oriented interface
 * @export
 * @class ProBroadcastApi
 * @extends {BaseAPI}
 */
export class ProBroadcastApi extends BaseAPI {
    /**
     * Edit preset.
     * @summary Edit Preset
     * @param {string} presetUuid 
     * @param {string} deviceUuid 
     * @param {PresetProOverlaysDTO} presetProOverlaysDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid: string, deviceUuid: string, presetProOverlaysDTO: PresetProOverlaysDTO, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).editPresetProDevicesDeviceUuidPresetsPresetUuidPatch(presetUuid, deviceUuid, presetProOverlaysDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feed Image
     * @param {string} presetUuid 
     * @param {FeedNameEnum} feedName 
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid: string, feedName: FeedNameEnum, deviceUuid: string, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).getFeedImageProDevicesDeviceUuidPresetsPresetUuidFeedFeedNameGet(presetUuid, feedName, deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the options available with all the options for field details form (1st step)
     * @summary Get Field Configuration
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).getFieldConfigurationProDevicesDeviceUuidConfigurationGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Field Options
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).getFieldOptionsProDevicesDeviceUuidOptionsDetailsGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get presets for device.
     * @summary Get Preset Details
     * @param {string} presetUuid 
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid: string, deviceUuid: string, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).getPresetDetailsProDevicesDeviceUuidPresetsPresetUuidGet(presetUuid, deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get presets for device.
     * @summary Get Presets
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid: string, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).getPresetsProDevicesDeviceUuidPresetsGet(deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Camera Config
     * @param {string} deviceUuid 
     * @param {EditDeviceDetailsDTO} editDeviceDetailsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid: string, editDeviceDetailsDTO: EditDeviceDetailsDTO, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).setCameraConfigProDevicesDeviceUuidConfigurationCameraConfigPost(deviceUuid, editDeviceDetailsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set all the options available with all the options for field details form (1st step)
     * @summary Set Field Configuration
     * @param {string} deviceUuid 
     * @param {SetFieldConfig} setFieldConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProBroadcastApi
     */
    public setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid: string, setFieldConfig: SetFieldConfig, options?: AxiosRequestConfig) {
        return ProBroadcastApiFp(this.configuration).setFieldConfigurationProDevicesDeviceUuidConfigurationPost(deviceUuid, setFieldConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SoftwaresApi - axios parameter creator
 * @export
 */
export const SoftwaresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Software
         * @param {SoftwareNameEnum} software 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareSoftwaresSoftwareGet: async (software: SoftwareNameEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'software' is not null or undefined
            assertParamExists('getSoftwareSoftwaresSoftwareGet', 'software', software)
            const localVarPath = `/softwares/{software}/`
                .replace(`{${"software"}}`, encodeURIComponent(String(software)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoftwaresApi - functional programming interface
 * @export
 */
export const SoftwaresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SoftwaresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Software
         * @param {SoftwareNameEnum} software 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSoftwareSoftwaresSoftwareGet(software: SoftwareNameEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoftwareAvailableDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSoftwareSoftwaresSoftwareGet(software, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SoftwaresApi - factory interface
 * @export
 */
export const SoftwaresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SoftwaresApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Software
         * @param {SoftwareNameEnum} software 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareSoftwaresSoftwareGet(software: SoftwareNameEnum, options?: any): AxiosPromise<SoftwareAvailableDTO> {
            return localVarFp.getSoftwareSoftwaresSoftwareGet(software, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SoftwaresApi - object-oriented interface
 * @export
 * @class SoftwaresApi
 * @extends {BaseAPI}
 */
export class SoftwaresApi extends BaseAPI {
    /**
     * 
     * @summary Get Software
     * @param {SoftwareNameEnum} software 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoftwaresApi
     */
    public getSoftwareSoftwaresSoftwareGet(software: SoftwareNameEnum, options?: AxiosRequestConfig) {
        return SoftwaresApiFp(this.configuration).getSoftwareSoftwaresSoftwareGet(software, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMeUsersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuthTokenWithCookies required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuthTokenWithCookies", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMeUsersMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userMeUsersMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMeUsersMeGet(options?: any): AxiosPromise<UserDTO> {
            return localVarFp.userMeUsersMeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userMeUsersMeGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userMeUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }
}


