import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { AXIOS_DEFAULT_CONFIG } from './axiosConfig'
import { getInstanceBaseURL } from './helpers'
import { setInterceptors } from './interceptors'
import { setRequestHeadersInterceptors } from './interceptors/requestHeadersInterceptors'
import { setRequestsCountInterceptors } from './interceptors/requestCountInterceptor'

declare module 'axios' {
  export interface AxiosRequestConfig {
    disableTokenRefresh?: boolean
  }
}

class AxiosManager {
  private static axiosManager: AxiosManager
  private axiosInstance: AxiosInstance

  private constructor() {
    this.axiosInstance = axios.create(AXIOS_DEFAULT_CONFIG)
    this.axiosInstance.defaults.baseURL = getInstanceBaseURL()
    setRequestHeadersInterceptors(this.axiosInstance)
    setRequestsCountInterceptors(this.axiosInstance)
    setInterceptors(this.axiosInstance)
  }

  /**
   * Creates an AxiosManager or return a previously created instance
   * @returns {AxiosManager} Singleton axios manager instance
   */
  public static getInstance(): AxiosManager {
    if (!this.axiosManager) {
      this.axiosManager = new AxiosManager()
    }

    return this.axiosManager
  }

  public getAxiosInstance(): AxiosInstance {
    return this.axiosInstance
  }

  public addCommonHeader(headerKey: string, sessionToken: string) {
    this.axiosInstance.defaults.headers.common[headerKey] = sessionToken
  }

  /**
   * Binds the Bearer token to the Axios request Authorization header
   * @param request
   * @param {string} token token
   * @returns Request with token in the header
   */
  protected bindToken(request: AxiosRequestConfig<any>, token: string) {
    if (!request.headers || !token) return request

    request.headers.Authorization = `Bearer ${token}`
    return request
  }
}

export { AxiosManager }
