import {
  BaseDevicePresetProDTO,
  DeviceConfigurationDTO,
  DevicePresetDTO,
  EditDeviceConfigurationDTO,
} from 'api/api_code'
import { Key, ReactNode } from 'react'

export interface IndexSignature {
  [key: string]: any
}

export interface Sport {
  name: string
  illusName: string
  subSports?: Sport[]
  calibrated?: boolean
  sportFields?: string[]
}

export interface DestinationsType {
  key: Key
  name: string
  protocol: string
  uri: string
  enabled?: boolean
}

export interface Step {
  index: number
  camera?: string
  title?: string
  isCompleted: (
    data:
      | DevicePresetDTO
      | DeviceConfigurationDTO
      | EditDeviceConfigurationDTO
      | BaseDevicePresetProDTO
      | number
      | boolean
      | null
  ) => boolean
  component: ReactNode
  width?: number
}

export enum CameraLicense {
  PRO_BASIC = 'PRO_BASIC',
  PRO_ADVANCED = 'PRO_ADVANCED',
  BROADCAST_BASIC = 'BROADCAST_BASIC',
  BROADCAST_ADVANCED = 'BROADCAST_ADVANCED',
  ATV_ONE = 'ATV_ONE',
  ATV_ACE = 'ATV_ACE',
}
