import {
  AuthenticationApi,
  CalibrationApi,
  CamerasApi,
  DeviceConfigurationApi,
  DevicesApi,
  OptionsApi,
  OrganizationsApi,
  PresetsApi,
  SoftwaresApi,
  UsersApi,
  ProBroadcastApi,
} from 'api/api_code'
import { AxiosManager } from './axios'

const axiosInstance = AxiosManager.getInstance().getAxiosInstance()

export const AuthenticationEndpoints = new AuthenticationApi(undefined, undefined, axiosInstance)
export const OrganizationsEndpoints = new OrganizationsApi(undefined, undefined, axiosInstance)
export const CalibrationEndpoints = new CalibrationApi(undefined, undefined, axiosInstance)
export const CamerasEndpoints = new CamerasApi(undefined, undefined, axiosInstance)
export const DeviceConfigurationEndpoints = new DeviceConfigurationApi(
  undefined,
  undefined,
  axiosInstance
)
export const DeviceDetailsEndpoints = new DevicesApi(undefined, undefined, axiosInstance)
export const OptionsEndpoints = new OptionsApi(undefined, undefined, axiosInstance)
export const PresetsEndpoints = new PresetsApi(undefined, undefined, axiosInstance)
export const ProBroadcastEndpoints = new ProBroadcastApi(undefined, undefined, axiosInstance)
export const SoftwaresEndpoints = new SoftwaresApi(undefined, undefined, axiosInstance)
export const UsersEndpoints = new UsersApi(undefined, undefined, axiosInstance)
