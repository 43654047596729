import { HttpStatusCode } from 'axios'
import { AXIOS_DEFAULT_CONFIG } from './axiosConfig'
import { BASE_PATH } from '../api_code/base'

function defaultErrorCallback(error) {
  console.warn(error)
}

export const getInstanceBaseURL = () => {
  if (
    typeof window !== 'undefined' &&
    process.env.NODE_ENV !== 'development' &&
    !window.location.hostname.includes('localhost')
  ) {
    return `https://${window.location.hostname.replace('wizard', 'api.atvdevices')}`
  }
  return AXIOS_DEFAULT_CONFIG.baseURL
}

export const getBaseURL = () => {
  return `${getInstanceBaseURL()}${BASE_PATH}`
}

export const requestHandler = async (
  axiosPromise: Promise<any>,
  onOkCallback?: CallableFunction,
  onErrorCallback: CallableFunction = defaultErrorCallback
) => {
  try {
    const result = await axiosPromise
    if (
      result.status === HttpStatusCode.Ok ||
      result.status === HttpStatusCode.Created ||
      result.status === HttpStatusCode.NoContent
    )
      if (onOkCallback === null) return
    onOkCallback(result.data, result['pendingRequests'])
  } catch (error) {
    if (error?.name === 'AxiosError') {
      if (error.response?.status >= 400 && error.response?.status <= 499) {
        onErrorCallback(error)
      }
      if (error.response?.status >= HttpStatusCode.InternalServerError) {
        onErrorCallback(error)
      }
    } else onErrorCallback(error)
  }
}

export const requestRetryTimeout = (delay: number) => {
  let timeout: NodeJS.Timeout
  const promise = new Promise((resolve) => {
    timeout = setTimeout(resolve, delay)
  })
  return {
    promise,
    timeout,
  }
}
