import { create } from 'zustand'
import { CameraDetailsDTO } from 'api/api_code'

import { persist, createJSONStorage, devtools } from 'zustand/middleware'
import { CameraLicense } from 'types/global'

type CameraAction = {
  _reset: () => void
}

const initialState: CameraDetailsDTO = {
  uuid: '',
  name: '',
  software_installed: [],
  software_installing: [],
  status: 'offline',
  producer: { serial_number: '', license_ticket_id: '', license_type: '', hostname: '' },
  fields: [{ uuid: '', name: '', timezone: '' }],
  framerate: null,
  lens_size: null,
  organization: { name: '' },
}

const cameraPersistOpts = {
  name: 'camera-store',
  storage: createJSONStorage(() => sessionStorage),
  // Save camera lens type for calibration logic
  partialize: (
    state: CameraDetailsDTO & CameraAction
  ): Partial<CameraDetailsDTO & CameraAction> => ({
    lens_size: state.lens_size,
    producer: state.producer,
  }),
}

const injectMiddlwares = (f) => devtools(persist(f, cameraPersistOpts))

const useCameraStore = create<CameraDetailsDTO & CameraAction>()(
  injectMiddlwares((set) => ({ ...initialState, _reset: () => set(initialState) }))
)

const isXSSelector = (state: CameraDetailsDTO) => state.lens_size === 'XS'
const isProBroadcastSelector = (state: CameraDetailsDTO) => {
  const license = state.producer.license_type
  return license !== CameraLicense.ATV_ACE && license !== CameraLicense.ATV_ONE && license !== ''
}
const isOneSelector = (state: CameraDetailsDTO) => {
  const license = state.producer.license_type
  return license === CameraLicense.ATV_ONE
}

export { useCameraStore, isXSSelector, isProBroadcastSelector, isOneSelector }
