import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

let pendingRequests = 0

const setRequestsCountInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      pendingRequests++
      return config
    },
    (error) => {
      pendingRequests--
      return Promise.reject(error)
    }
  ),
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        pendingRequests--
        response['pendingRequests'] = pendingRequests
        return response
      },
      (error) => {
        pendingRequests--
        return Promise.reject(error)
      }
    )
}

export { setRequestsCountInterceptors }
