import {
  BASKETBALL,
  FOOTBALL,
  FOOTSAL,
  GENERIC,
  HANDBALL,
  HOCKEY,
  VOLEYBALL,
} from 'components/UI/Icon/icons/illustration.constants'

export const SPORT = {
  '5f162497-d0cd-45ec-9b39-44541f21beaa': {
    illustration: FOOTBALL,
    id: 'football11',
    name: 'Football 11',
    background: 'Img_football11.jpg',
  },
  'bd817c89-0915-47ba-8136-eda0c1bb9757': {
    illustration: FOOTBALL,
    id: 'football7',
    name: 'Football 7',
    background: 'Img_football7.jpg',
  },
  'e6c4a6b1-fe10-4175-ad9d-f0fb9b47a171': {
    illustration: BASKETBALL,
    id: 'basketball',
    name: 'Basketball',
    background: 'Img_basketball.jpg',
  },
  '98e8f622-cfce-4b6b-9cee-a00c538b16f2': {
    illustration: FOOTSAL,
    id: 'futsal',
    name: 'Futsal',
    background: 'Img_futsal.jpg',
  },
  'ca561e5c-d99f-4d8a-8078-38ca746f8ac2': {
    illustration: HANDBALL,
    id: 'handball',
    name: 'Handball',
    background: 'Img_handball.jpg',
  },
  '3cb6fb09-189b-4852-a50d-bb9caad8b641': {
    illustration: HOCKEY,
    id: 'ice_hockey',
    name: 'Ice hockey',
    background: 'Img_ice_hockey.jpg',
  },
  '0ea41ae5-26ca-4851-b495-f8de56f1fb77': {
    illustration: HOCKEY,
    id: 'hockey',
    name: 'Roller hockey',
    background: 'Img_roller_hockey.jpg',
  },
  '57b970aa-2c00-48b6-ab1c-7a9c884bfe1d': {
    illustration: VOLEYBALL,
    id: 'volleyball',
    name: 'Volleyball',
    background: 'Img_volleyball.jpg',
  },
  '668b307b-3ac5-4555-b76c-26751c0b3d08': {
    illustration: GENERIC,
    id: 'generic',
    name: 'Generic',
    background: 'Img_generic.jpg',
  },
  Football: {
    illustration: FOOTBALL,
  },
  Basketball: {
    illustration: BASKETBALL,
  },
  Futsal: {
    illustration: FOOTSAL,
  },
  Handball: {
    illustration: HANDBALL,
  },
  Hockey: {
    illustration: HOCKEY,
  },
  Volleyball: {
    illustration: VOLEYBALL,
  },
  Generic: {
    illustration: GENERIC,
  },
}

export const DEFAULT_USER_IMAGE = '/icons/logo-user.png'

export const sportsUUIDBySportId = {
  football11: '5f162497-d0cd-45ec-9b39-44541f21beaa',
  football7: 'bd817c89-0915-47ba-8136-eda0c1bb9757',
  basketball: 'e6c4a6b1-fe10-4175-ad9d-f0fb9b47a171',
  futsal: '98e8f622-cfce-4b6b-9cee-a00c538b16f2',
  handball: 'ca561e5c-d99f-4d8a-8078-38ca746f8ac2',
  ice_hockey: '3cb6fb09-189b-4852-a50d-bb9caad8b641',
  hockey: '0ea41ae5-26ca-4851-b495-f8de56f1fb77',
  volleyball: '57b970aa-2c00-48b6-ab1c-7a9c884bfe1d',
  generic: '668b307b-3ac5-4555-b76c-26751c0b3d08',
}

export const periodDurationBySport = {
  handball: '30:00',
  football7: '45:00',
  football11: '45:00',
  futsal: '20:00',
  hockey: '20:00',
  ice_hockey: '20:00',
  basketball: '10:00',
}

export const numPeriodsBySport = {
  handball: 2,
  football7: 2,
  football11: 2,
  futsal: 2,
  hockey: 2,
  ice_hockey: 3,
  basketball: 4,
  volleyball: 5,
}
